import { FilterType } from '../../components/field/advancedDatePicker/type';

export enum DealStatus {
  Created = '0',
  InformationRequested = '1',
  AwaitingDocuments = '2',
  SwoopReview = '3',
  Qualified = '4',
  Matched = '9',
  Underwriting = '6',
  Offer = '5',
  OfferAccepted = '10',
  Funded = '11',
  ClosedWon = '7',
  ClosedLost = '8',
}

export enum DealTypes {
  // Has subtypes
  LOANS = '1',
  EQUITY = '2',
  TAX = '7',
  INSURANCE = '8',
  FX = '9',
  BANKACCOUNT = '12',

  // No Subtypes
  NONE = '0',
  GRANTS = '3',
  PENSION = '10',
  ENERGY = '11',
  CREDITCARD = '13',
}

export interface Deal {
  dateModified: string;
  fieldValues: FieldValue[];
  applications: Application[];
  creatorType: CreatorType;
}

export interface FieldValue {
  createdBy: string;
  modifiedBy: string;
  dateCreated: string;
  dateModified: string;
  name: string;
  value: string;
  dataType: string;
}

export interface Application {
  funderCommission: number;
  netFee: number;
  clientFee: number;
  id: string;
  opportunityId: string;
  companyId: string;
  companyName: string;
  productId: string;
  productName: string;
  productType: string;
  fundingManager: string;
  providerName: string;
  status: string;
  dateCreated: string;
  dateModified: string;
  fundingAmount: number;
  introducerFee: number;
  isDeleted: boolean;
  creatorType: CreatorType;
}

export const EquityOptions: { [key: string]: number } = {
  SEIS: 101,
  EIS: 102,
  VCT: 103,
  VC: 104,
  Angel: 100,
  PrivateEquity: 106,
  FamilyOffice: 105,
  Crowdfunding: 107,
};

export enum CreatorType {
  Client = 'Client',
  Broker = 'Broker',
  Advisor = 'Advisor',
}

export type Filters = {
  orderBy: string;
  direction: 'asc' | 'desc';
  ownerIds: string[];
  statuses: string[];
  LastProgressedFilterType: FilterType | null;
  LastProgressedFrom: Date | null;
  LastProgressedTo: Date | null;
  hideCompleted: boolean;
  companyName: string;
  pageSize: number;
  pageNumber: number;
  creatorTypes: CreatorType[];
  subtypes: string[];
};

export enum FilterSearchParam {
  OrderBy = 'orderBy',
  Direction = 'direction',
  OwnerIds = 'ownerIds',
  Statuses = 'statuses',
  HideCompleted = 'hideCompleted',
  CompanyName = 'companyName',
  PageSize = 'pageSize',
  PageNumber = 'pageNumber',
  LastProgressedFilterType = 'LastProgressedFilterType',
  LastProgressedFrom = 'LastProgressedFrom',
  LastProgressedTo = 'LastProgressedTo',
  CreatorTypes = 'creatorTypes',
  subtypes = 'subtypes',
}
