import { shortMonthNames } from './constants';

export function convertToISODate(value: string | number | Date) {
  const date = new Date(value);
  return date.toISOString().substring(0, 10);
}

export function convertToMonthNameAndYear(value: string | Date) {
  try {
    const date = new Date(value);
    return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
  } catch (e) {
    return value;
  }
}

export function convertTimestampToLongMonthAndYear(timestamp: number) {
  const date = new Date(timestamp * 1000);
  return `${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`;
}

export const convertToDateToTimestamp = (date: string) => {
  return new Date(date).getTime() / 1000;
};

export function convertToLocalDateFormat(value: string | Date | number) {
  try {
    return (
      (value &&
        new Date(value).toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })) ||
      new Date().toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })
    );
  } catch (e) {
    return value;
  }
}

export const convertToLocalDateTime = (value: string) => {
  const date = new Date(value);
  const formattedDate = date.toLocaleString(undefined, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });

  return formattedDate;
};

export function convertMonthNumberToYearsAndMonths(value: number) {
  if (!Number(value)) return value;

  const years = Math.floor(value / 12);
  const months = value % 12;

  const yearsString = years === 1 ? `${years} year` : years > 0 ? `${years} years` : '';
  const monthString = months === 1 ? `${months} month` : months > 0 ? `${months} months` : '';

  return yearsString && monthString
    ? `${yearsString}, ${monthString}`
    : monthString
    ? monthString
    : yearsString;
}

export function converDateMonthYearStringToISOString(value: string) {
  const conversionRegex = /(\d+[/])(\d+[/])/;
  const replacedDate = value.replace(conversionRegex, '$2$1');

  return new Date(replacedDate).toISOString();
}

export function convertToLocalDateTimeFormat(value: string | Date | number) {
  try {
    return `${new Date(value).toLocaleTimeString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })}`;
  } catch (e) {
    return value;
  }
}

export function getYearsList() {
  const currentYear = new Date().getFullYear();
  const range = (start: number, stop: number, step: number) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
  return range(currentYear, currentYear - 100, -1);
}

export const getMonthName = (monthNumber: number) => {
  return shortMonthNames[monthNumber - 1] || '';
};
