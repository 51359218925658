import i18next from 'i18next';

import { ValidationTypes, FormFieldPropType, FieldMetaDataPropType, FieldTypes } from './types';

export const falsyValues = [undefined, '', null, NaN];

const requiredErrorMessageHandler = (
  hasCustomRequiredErrorMessage: boolean | undefined,
  fieldName: string | undefined
) => {
  if (!fieldName) return '';
  return hasCustomRequiredErrorMessage
    ? `validation:customerrormessages:${fieldName?.toLowerCase()}`
    : 'validation:required';
};

function email(props: FormFieldPropType) {
  if (props.required && !props.value)
    return requiredErrorMessageHandler(props?.hasCustomRequiredErrorMessage, props?.name);

  if (props.value && !/\S+@\S+\.\S+/.test(props.value)) return 'validation:email:invalid';

  return '';
}

function password(props: FormFieldPropType) {
  if (props.required && !props.value)
    return requiredErrorMessageHandler(props?.hasCustomRequiredErrorMessage, props?.name);

  if (props.minimumLength && props.value && props.value.length < props.minimumLength)
    return 'validation:password:minInvalid';

  const passwordRegex = /^.*((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}).*$/;

  if (props.value && !passwordRegex.test(props.value)) return 'validation:password:notstrong';

  return '';
}
function confirmPassword(props: FormFieldPropType, confirmPassword: string) {
  const passwordValidation = password(props);

  if (passwordValidation) return passwordValidation;

  if (props.value !== confirmPassword) {
    return 'validation:password:confirmpassword';
  }

  return '';
}

function confirmEmail(props: FormFieldPropType, confirmEmail: string) {
  const emailValidation = email(props);

  if (emailValidation) return emailValidation;

  if (props.value !== confirmEmail) {
    return 'validation:email:confirmemail';
  }

  return '';
}

function text(props: FormFieldPropType & { [key: string]: any }) {
  if (props.parentKey && props.stateValues[props.parentKey]) {
    if (props.fieldType === FieldTypes.AUTOCOMPLETE && !props.stateValues[props.name!])
      return 'validation:required';

    if (!props.value) return 'validation:required';
  }

  if (props.required && !props.value)
    return requiredErrorMessageHandler(props?.hasCustomRequiredErrorMessage, props?.name);

  return '';
}

function phonenumber(props: FormFieldPropType) {
  if (props.required && !props.value)
    return requiredErrorMessageHandler(props?.hasCustomRequiredErrorMessage, props?.name);

  const contactNumberRegex = /^\+[0-9]?()[0-9](\s|\S)(\d[0-9]{9})$/;

  if (props.value && !contactNumberRegex.test(props.value)) return 'validation:phone:invalid';

  return '';
}

function selectbox(props: FormFieldPropType & { [key: string]: any }) {
  if (props.parentKey && props.stateValues[props.parentKey] && !props.value)
    return 'validation:required';

  if (props.required && !props.value)
    return requiredErrorMessageHandler(props?.hasCustomRequiredErrorMessage, props?.name);

  return '';
}

function radio(props: FormFieldPropType) {
  if (props.required && falsyValues.includes(props.value))
    return requiredErrorMessageHandler(props?.hasCustomRequiredErrorMessage, props?.name);

  return '';
}

function checkbox(props: FormFieldPropType & { [key: string]: any }) {
  if (
    props.parentKey &&
    props.stateValues[props.parentKey] &&
    !props.selectionKey?.replace(/,/gi, '')
  )
    return 'validation:required';

  if (
    props.required &&
    typeof props.selectionKey === 'string' &&
    !props.selectionKey.replace(/,/gi, '')
  ) {
    return 'validation:checkbox:atleastone';
  }

  return '';
}

function numeric(props: FormFieldPropType) {
  if ((props.min || props.min === 0) && props.min > +props.value) {
    return i18next.t('validation:numeric:min') + ` ${props.min}`;
  }

  if ((props.max || props.max === 0) && props.max < props.value) {
    return i18next.t('validation:numeric:max') + ` ${props.max}`;
  }

  if (props.required && !props.value)
    return requiredErrorMessageHandler(props?.hasCustomRequiredErrorMessage, props?.name);

  const sanitisedValue = props.value?.toString().replace(/,/gi, '');

  const numericRegex = /^\d+$/;
  const numericRegexNoDecimal = /\D/;

  if (
    props.value &&
    !numericRegex.test(sanitisedValue) &&
    !numericRegexNoDecimal.test(sanitisedValue)
  )
    return 'validation:numeric:invalid';

  return '';
}

function percentage(props: FormFieldPropType) {
  if (props.required && !props.value)
    return requiredErrorMessageHandler(props?.hasCustomRequiredErrorMessage, props?.name);

  const floatedNumber = parseFloat(props.value);

  if (isNaN(floatedNumber) || floatedNumber < 0 || floatedNumber > 100)
    return 'validation:percentage:invalid';

  return '';
}

function autocomplete(props: FormFieldPropType) {
  if (props.required && !props.value.length && !Object.keys(props.value).length)
    return 'validation:required';

  return '';
}

function currency(props: FormFieldPropType) {
  if (props.required && !props.value)
    return requiredErrorMessageHandler(props?.hasCustomRequiredErrorMessage, props?.name);

  return '';
}

function customSelect(props: FormFieldPropType) {
  if (props.required && !props.value.length)
    return requiredErrorMessageHandler(props?.hasCustomRequiredErrorMessage, props?.name);

  return '';
}

function multiselect(props: FormFieldPropType) {
  if (props.required && Array.isArray(props.value) && !props.value.length) {
    return requiredErrorMessageHandler(props?.hasCustomRequiredErrorMessage, props?.name);
  }

  return '';
}

function date(props: FormFieldPropType) {
  if (props.max && props.max < props.value) {
    return i18next.t('validation:date:max') + ` ${props.max}`;
  }

  if (props.min && props.min > props.value) {
    return i18next.t('validation:date:min') + ` ${props.max}`;
  }

  if (props.required && !props.value) {
    return requiredErrorMessageHandler(props?.hasCustomRequiredErrorMessage, props?.name);
  }
  return '';
}

function validate(props: FieldMetaDataPropType, stateValues?: any) {
  let errors: { [key: string]: any } = {};
  const name = props.name!;

  switch (props.validationtype) {
    case ValidationTypes.EMAIL:
      errors[name] = email(props);
      break;

    case ValidationTypes.PASSWORD:
      errors[name] = password(props);
      break;

    case ValidationTypes.CONFIRM_PASSWORD:
      errors[name] = confirmPassword(props, stateValues.password);
      break;

    case ValidationTypes.CONFIRM_EMAIL:
      errors[name] = confirmEmail(props, stateValues.email);
      break;

    case ValidationTypes.TEXT:
      errors[name] = text({ ...props, stateValues });
      break;

    case ValidationTypes.PHONENUMBER:
      errors[name] = phonenumber(props);
      break;

    case ValidationTypes.SELECT:
      errors[name] = selectbox({ ...props, stateValues });
      break;

    case ValidationTypes.RADIO:
      errors[name] = radio(props);
      break;

    case ValidationTypes.CHECKBOX:
      errors[name] = checkbox({ ...props, stateValues });
      break;

    case ValidationTypes.NUMERIC:
      errors[name] = numeric(props);
      break;

    case ValidationTypes.PERCENTAGE:
      errors[name] = percentage(props);
      break;

    case ValidationTypes.AUTOCOMPLETE:
      errors[name] = autocomplete(props);
      break;

    case ValidationTypes.CURRENCY:
      errors[name] = currency(props);
      break;

    case ValidationTypes.CUSTOM_SELECT:
      errors[name] = customSelect(props);
      break;

    case ValidationTypes.MULTISELECT:
      errors[name] = multiselect(props);
      break;

    case ValidationTypes.DATE:
      errors[name] = date(props);
      break;
  }

  if (props.children) {
    props.children.forEach((x) => {
      errors = { ...errors, ...validate(x, stateValues) };
    });
  }

  return errors;
}

export default function FieldValidation(props: Array<FieldMetaDataPropType>, stateValues?: any) {
  let errors: { [key in ValidationTypes]: any } = {};

  props.forEach((x: FieldMetaDataPropType) => {
    if (x?.children?.length) {
      x.children.forEach((child: FormFieldPropType) => {
        errors = { ...errors, ...validate(child, stateValues) };
      });
    }

    if (x?.disabled) return;

    if (x?.dependsOn) {
      const foundField = props.find(
        (field) => !Array.isArray(x?.dependsOn) && field.name === x?.dependsOn?.name
      );
      if (
        foundField &&
        !Array.isArray(x?.dependsOn) &&
        String(foundField.value) !== String(x?.dependsOn?.value)
      )
        return;
    }

    errors = { ...errors, ...validate(x, stateValues) };
  });

  return errors;
}
