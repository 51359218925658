import { AmplitudeTrackingEnum } from 'config/amplitude';
import { XApplicationTypes } from '_shared/utils/application';
import { HistoricalData } from 'pages/companyOverview/types';

export enum ValidationTypes {
  EMAIL = 1 << 0,
  PASSWORD = 1 << 1,
  TEXT = 1 << 2,
  PHONENUMBER = 1 << 3,
  SELECT = 1 << 4,
  RADIO = 1 << 5,
  CHECKBOX = 1 << 6,
  NUMERIC = TEXT & (1 << 7),
  PERCENTAGE = 1 << 8,
  AUTOCOMPLETE = 1 << 9,
  CURRENCY = 1 << 10,
  CUSTOM_SELECT = 1 << 11,
  MULTISELECT = 1 << 12,
  DATE = 1 << 13,
  CONFIRM_PASSWORD = 1 << 14,
  CONFIRM_EMAIL = 1 << 15,
}
export enum FieldTypes {
  TEXT,
  TEXTAREA,
  SELECT,
  CUSTOM_SELECT,
  MULTISELECT,
  CHECKBOX,
  RADIO,
  AUTOCOMPLETE,
  DATEPICKER,
  READONLY,
  UPLOAD,
  CURRENCY,
  EDITOR,
  SECTION_TITLE,
  PERCENTAGE,
}
export enum FieldPosition {
  GROUPLEFT = 1 << 1,
  GROUPRIGHT = 1 << 2,
}
export const NumberformatType = {
  COMMARISE: 'commarise',
};
export const TextformatType = {
  AUTOCOMPLETE: 'autocomplete',
  DATEPICKER: 'datepicker',
  MULTISELECT: 'multiselect',
  SWITCH: 'switch',
};
export type OptionsType = {
  label: string;
  value: any;
  name?: string;
  parentId?: string;
};
export type FormFieldPropType = {
  id?: string;
  name?: string;
  validationtype?: ValidationTypes;
  value?: any;
  defaultValue?: any;
  minimumLength?: number;
  maximumLength?: number;
  min?: number;
  max?: number;
  required?: boolean;
  label?: string;
  type?: string | ReadOnlyTypes;
  placeholder?: string;
  position?: FieldPosition;
  fieldType?: FieldTypes;
  dataType?: string;
  options?: Array<OptionsType>; // used for selection type input
  selectionKey?: string; // used for selection type input
  toolTip?: boolean;
  toolTipContent?: string;
  trailingIcon?: string;
  leadingIcon?: string;
  helperText?: string;
  format?: string;
  parentKey?: string; // used for dependency validation
  hasPermissions?: boolean;
  disabled?: boolean;
  classNames?: ClassNames;
  hasCustomRequiredErrorMessage?: boolean;
};

export type ClassNames = {
  field?: string;
  label?: string;
  control?: string;
  value?: string;
  action?: string;
  error?: string;
  errorIcon?: string;
  checkbox?: {
    checkbox?: string;
    isChecked?: string;
    inputCheckbox?: string;
    holder?: string;
    icon?: string;
  };
  labelToolTip?: string;
  icon?: string;
};

export enum DependsOnCondition {
  OR,
}

export enum DependencyRule {
  OR = 'or',
  ON = 'on',
  AND = 'and',
}

export enum fieldDependencyTypes {
  DISABLE,
  REMOVE,
}

export type DependsOnField = {
  name: string;
  value: any;
  dependencyType?: fieldDependencyTypes;
};

export type FieldMetaDataPropType = FormFieldPropType & {
  id?: string;
  name?: string;
  label?: string;
  children?: Array<FormFieldPropType>;
  hasPermissions?: boolean;
  link?: string;
  action?: ActionTypes;
  dependsOn?: DependsOnField | DependsOnField[];
  metadata?: {
    optionsType?: string;
    country?: string;
    requiredParentField?: string;
  };
  dependsOnCondition?: DependsOnCondition;
  boolean?: boolean;
  subValues?: Array<string>;
  classNames?: ClassNames;
  tabIndex?: number;
  tracking?: AmplitudeTrackingEnum;
  callBack?: Function;
  readOnly?: boolean;
  disableRemove?: string;
  valueAsString?: boolean;
  enabled?: boolean;
  autoComplete?: AutoCompleteTypes;
  enableAutofocus?: boolean;
  displayOnApp?: XApplicationTypes;
  hideFileValues?: boolean;
  hidden?: boolean;
  layout?: LayoutTypes;
  toolTipContent?: string;
  acceptedFileTypes?: string;
  historicalStatusData?: HistoricalData | null;
  fieldToDisable?: string;
  dependencyRule?: DependencyRule;
  isLoading?: boolean;
};

export enum LayoutTypes {
  GROUPED = 'grouped',
  INLINE = 'inline',
}

export enum ReadOnlyTypes {
  TEXT = 'text',
  ANCHOR = 'anchor',
  ICON = 'icon',
  LIST = 'list',
  LINK = 'link',
  NUMBER = 'number',
}

export enum ActionTypes {
  EDIT = 'edit',
  ALERT = 'alert',
}

export enum AutoCompleteTypes {
  AUTOCOMPLETEON = 'on',
  AUTOCOMPLETEOFF = 'off',
}
