import React, { useState } from 'react';
import { isAdvisorApplication, SWOOP_GROUP_ID } from '_shared/utils/application';
import { useAppState } from 'store';
import AddMatchedFunderPanel from '../../../deals/components/DealDetailsPage/AddMatchedFunderPanel';
import { useParams } from 'react-router-dom';
import EditRequirementModal from '../../../deals/components/DealDetailsPage/EditRequirementModal';
import CreateDealModal from '../../../deals/components/createDealModal';
import { OfferTypes } from '../../types';
import { getFundingForm, getFundingFormV2 } from '_shared/api/fundingMatches';
import { env } from '_shared/utils';

type DealTemplate = {
  productType?: OfferTypes;
  productSubtype?: string;
  amount?: number;
};

const mapProductType = (type: string) => {
  return type === 'Loan'
    ? OfferTypes.LOANS
    : type === 'Equity'
    ? OfferTypes.EQUITY
    : OfferTypes.GRANTS;
};

const FundingMatches: React.FC = () => {
  const { id: companyId } = useParams();
  const { state } = useAppState();
  const [productIdBeingAdded, setProductIdBeingAdded] = useState('');
  const [editingSubtypeId, setEditingSubtypeId] = useState<string | null>(null);
  const [isCreateDealOpen, setIsCreateDealOpen] = useState(false);
  const [dealTemplate, setDealTemplate] = useState<DealTemplate | null>(null);
  const [cachedRequestedAmounts, setCachedRequestedAmounts] = useState<Record<string, number>>({});

  const onSubtypeChange = async (id: string) => {
    if (!id) {
      return;
    }

    if (cachedRequestedAmounts[id]) {
      setDealTemplate((prev) => ({
        ...prev,
        amount: cachedRequestedAmounts[id],
      }));
    } else {
      const version = env('REACT_APP_DYNAMIC_FORMS_ENDPOINTS_VERSION');
      const formData = await fetchFormData(version, id, companyId!);

      const form =
        version === 'v2'
          ? {
              displayName: formData[1]?.displayName,
              formId: formData[1]?.formId,
              formSections: formData,
              name: formData[1]?.name,
            }
          : formData;

      const allFormFields = form.formSections.reduce(
        (accumulator: any[], section: any) => [...accumulator, ...section.formFields],
        []
      );
      const requestedAmount = allFormFields.find(
        ({ name }: any) => name === 'fundingAmount'
      )?.value;

      if (requestedAmount) {
        setCachedRequestedAmounts((prev) => ({
          ...prev,
          [id]: parseInt(requestedAmount),
        }));
        setDealTemplate((prev) => ({
          ...prev,
          amount: parseInt(requestedAmount),
        }));
      }
    }
  };

  const fetchFormData = async (version: string, activeTab: string, companyId: string) => {
    const { data } =
      version === 'v2'
        ? await getFundingFormV2(activeTab, companyId)
        : await getFundingForm(activeTab, companyId);
    return version === 'v2' ? data.sections : data;
  };

  const startNewDeal = (productId: string, opportunity: any) => {
    setDealTemplate((prev) => ({
      ...prev,
      productType: mapProductType(opportunity.type),
      productSubtype: opportunity.subcategory,
    }));
    setIsCreateDealOpen(true);
    setProductIdBeingAdded(productId);
  };

  return (
    <>
      <AddMatchedFunderPanel
        companyId={companyId!}
        inDealProductIds={[]}
        isSwoopGroupUser={state.system.groupId === SWOOP_GROUP_ID}
        readonly={isAdvisorApplication}
        addDealLabel={'Start New Deal'}
        onAddToDeal={startNewDeal}
        onEditRequirements={(id) => {
          setEditingSubtypeId(id);
        }}
        onSubtypeIdChange={onSubtypeChange}
      />
      {editingSubtypeId && (
        <EditRequirementModal
          isModalOpen
          companyId={companyId!}
          subtypeId={editingSubtypeId}
          onClose={() => {
            setEditingSubtypeId(null);
          }}
        />
      )}

      {isCreateDealOpen && (
        <CreateDealModal
          companyId={companyId}
          isCreateDealOpen={isCreateDealOpen}
          setIsCreateDealOpen={setIsCreateDealOpen}
          productId={productIdBeingAdded}
          initialProductType={dealTemplate?.productType}
          initialProductSubtype={dealTemplate?.productSubtype}
          initialAmount={dealTemplate?.amount}
        />
      )}
    </>
  );
};

export default FundingMatches;
