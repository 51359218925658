import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/modal';
import cn from 'classnames';
import styles from 'pages/deals/assets/createDealModal.module.scss';
import Button from 'components/button';
import { ButtonStyleTypeEnum } from 'components/button/type';
import UserSelect from 'components/userSelect';
import CurrencyInput from 'components/v2/CurrencyInput';
import Select from 'components/field/select';
import { capitalizeFirstLetter } from '_shared/utils/string';
import {
  dealsTypeList,
  dealTypeSubtypeBackendMappings,
  dealTypesWithoutSubtypesValue,
} from '../../constant';
import { useAppState } from 'store';
import { APIUsersByRoleType } from 'pages/users/type';
import { useQueryClient } from '@tanstack/react-query';
import {
  postDeal,
  postDealApplication,
  QueryKeys as BusinessesQueryKeys,
} from '_shared/api/businesses';
import Spinner from 'components/spinner';
import { TOAST_ERROR_MESSAGE, TOAST_MESSAGE } from 'store/toast/types';
import { ValidationTypes } from '_shared/fieldValidation/types';
import { ActionType, newDealReducer } from 'pages/deals/store/newDeal.reducer';
import { IContext } from 'store/types';
import { fetchUsers } from 'pages/deals/utils';
import { UPDATE_DEALS } from 'store/deals/types';
import { dealStatusListOptions } from '_shared/utils/constants';
import { AmplitudeTrackingEnum, sendAmplitudeData } from 'config/amplitude';
import { TOption } from '_shared/utils/form';
import { OfferTypes } from 'pages/fundingMatches/types';
import { CompanyDetailsContext } from 'pages/companyDetails/store/companyDetails.reducer';
import { DealTypes } from 'pages/deals/types';
import { RoutePath } from '_shared/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import useGetSubTypeMetaData from '_shared/api/marketplaceApi/useGetSubTypeMetaData';

const CreateDealModal = ({
  companyId,
  isCreateDealOpen,
  setIsCreateDealOpen,
  productId,
  initialProductType,
  initialProductSubtype,
  initialAmount,
}: {
  companyId: string | undefined;
  isCreateDealOpen: boolean;
  setIsCreateDealOpen: Function;
  productId?: string;
  initialProductType?: OfferTypes;
  initialProductSubtype?: string;
  initialAmount?: number;
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();

  const { refreshCompanyData } = useContext(CompanyDetailsContext);
  const { state: globalState, dispatch: globalDispatch }: IContext = useAppState();

  const [state, dispatch] = useReducer(newDealReducer, {
    dealType: '1',
    dealSubType: '',
    dealStage: '0',
    dealAmount: '',
    ownerList: [],
    selectedOwner: {},
    opportunityId: '',
    isFetchingUsers: true,
    isCreatingDeal: false,
  });

  const { data: loanSubTypeData } = useGetSubTypeMetaData(DealTypes.LOANS);
  const { data: equitySubTypeData } = useGetSubTypeMetaData(DealTypes.EQUITY);
  const { data: taxSubTypeData } = useGetSubTypeMetaData(DealTypes.TAX);
  const { data: insuranceSubTypeData } = useGetSubTypeMetaData(DealTypes.INSURANCE);
  const { data: bankAccountSubTypeData } = useGetSubTypeMetaData(DealTypes.BANKACCOUNT);
  const { data: fxSubTypeData } = useGetSubTypeMetaData(DealTypes.FX);

  const findDefaultUser = () => {
    const defaultSelectedUser = state.ownerList.find((user: any) => {
      return user.securityId === globalState.system.currentUser?.authId;
    });

    dispatch({
      type: ActionType.SET_SELECTED_OWNER,
      payload: defaultSelectedUser,
    });
  };

  const sendAmplitudeNewDealConfirmationData = () => {
    const dealSubtype =
      state.dealType === '1'
        ? loanSubTypeData?.data.dealSubTypeLoan
        : equitySubTypeData?.data.dealSubTypeEquity;
    const selectedSubtype = dealSubtype?.find((option: any) => option.value === state.dealSubType);
    const displaySubtypeLabel = selectedSubtype?.label ?? '';
    sendAmplitudeData(AmplitudeTrackingEnum.confirmnewdeal, {
      dealCreationPoint: window.location.href.includes('/funding-matches')
        ? 'Funding Matches'
        : 'Manual Creation',
      dealType: dealsTypeList[state.dealType - 1]?.label,
      dealSubtype: displaySubtypeLabel,
    });
  };

  const createDeal = async () => {
    if (!companyId) return;
    if (!dealTypesWithoutSubtypesValue.includes(state.dealType) && !state.dealSubType) {
      globalDispatch({
        type: TOAST_ERROR_MESSAGE,
        payload: { toastMessage: t('Please select subtype') },
      });
      return;
    }

    if (!state.dealAmount || parseInt(state.dealAmount) === 0) {
      globalDispatch({
        type: TOAST_ERROR_MESSAGE,
        payload: { toastMessage: t('Please enter deal amount') },
      });
      return;
    }

    if (parseInt(state.dealAmount) > 2147483647) {
      globalDispatch({
        type: TOAST_ERROR_MESSAGE,
        payload: { toastMessage: t('Deal amount is greater than max value') },
      });

      return;
    }

    dispatch({
      type: ActionType.SET_IS_CREATING_DEAL,
      payload: true,
    });

    try {
      const { data: opportunityId } = await postDeal(companyId, {
        applications: [],
        FieldValues: [
          {
            Name: 'Opportunity.Type',
            Value: state.dealType,
            readonly: false,
          },
          {
            Name: 'Opportunity.Subtype',
            Value: dealTypeSubtypeBackendMappings[state.dealType]
              ? dealTypeSubtypeBackendMappings[state.dealType]
              : state.dealSubType,
            readonly: false,
          },
          {
            Name: 'Opportunity.Stage',
            Value: state.dealStage,
            readonly: false,
          },
          {
            Name: 'Opportunity.Amount',
            Value: state.dealAmount || '0',
            readonly: false,
          },
          {
            Name: 'Opportunity.Owner',
            Value: state.selectedOwner.securityId,
            readonly: false,
          },
          {
            Name: 'Opportunity.OwnerName',
            Value: state.selectedOwner.fullName,
            readonly: false,
          },
          {
            Name: 'Opportunity.OwnerPhotoUrl',
            Value: state.selectedOwner.picture,
            readonly: false,
          },
        ],
      });

      dispatch({
        type: ActionType.SET_OPPORTUNITY_ID,
        payload: opportunityId,
      });

      if (productId) {
        await postDealApplication(companyId, productId, {
          productName: '',
          applicationStatus: 'Created',
          productCategory: 'BusinessLoan',
          opportunityId: opportunityId,
        });
      }

      globalDispatch({
        type: TOAST_MESSAGE,
        payload: { toastMessage: t('home:deals:createdealmodal:toast:success') },
      });

      if (opportunityId) {
        globalDispatch({
          type: UPDATE_DEALS,
          payload: {
            ...globalState.deals,
            companyId: companyId,
            dealId: opportunityId,
            detailsModalOpen: true,
          },
        });
        setIsCreateDealOpen(false);
      }

      navigate(
        RoutePath.companydeal.replace(':companyId', companyId).replace(':dealId', opportunityId),
        {
          state: {
            from: location.pathname,
            search: location.search,
          },
        }
      );

      void queryClient.invalidateQueries({ queryKey: [BusinessesQueryKeys.GetDeals] });
      void queryClient.invalidateQueries({ queryKey: [BusinessesQueryKeys.GetCompanies] });

      dispatch({
        type: ActionType.SET_IS_CREATING_DEAL,
        payload: false,
      });

      if (companyId) {
        void refreshCompanyData();
      }

      sendAmplitudeNewDealConfirmationData();
    } catch (error) {
      console.error(error);
      globalDispatch({
        type: TOAST_ERROR_MESSAGE,
        payload: { toastMessage: t('home:deals:createdealmodal:toast:failed') },
      });
      dispatch({
        type: ActionType.SET_IS_CREATING_DEAL,
        payload: false,
      });
    }
  };

  const isFetchingUsers = (loadState: boolean) => {
    dispatch({
      type: ActionType.SET_IS_FETCHING_USERS,
      payload: loadState,
    });
  };

  const setOwnerList = (ownerData: any) => {
    dispatch({
      type: ActionType.SET_OWNER_LIST,
      payload: ownerData,
    });
  };

  const assignOwner = async (selectedUser: APIUsersByRoleType) => {
    const relevantUserDetails =
      state.ownerList.find((user: any) => {
        return user.securityId === selectedUser.securityId;
      }) || null;

    if (!relevantUserDetails?.userId) return;

    try {
      dispatch({
        type: ActionType.SET_SELECTED_OWNER,
        payload: relevantUserDetails,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const currentSubTypesDisplayed = () => {
    if (dealTypesWithoutSubtypesValue.includes(state.dealType)) return;

    const subtypesMap: any = {
      [DealTypes.LOANS]: loanSubTypeData?.data.dealSubTypeLoan,
      [DealTypes.EQUITY]: equitySubTypeData?.data.dealSubTypeEquity,
      [DealTypes.TAX]: taxSubTypeData?.data.dealSubTypeTax,
      [DealTypes.INSURANCE]: insuranceSubTypeData?.data.dealSubTypeInsurance,
      [DealTypes.BANKACCOUNT]: bankAccountSubTypeData?.data.dealSubTypeBankAccount,
      [DealTypes.FX]: fxSubTypeData?.data.dealSubTypesFX,
    };

    return subtypesMap[state.dealType];
  };

  useEffect(() => {
    if (isCreateDealOpen && initialProductType) {
      const dealOfferTypeMap = {
        [OfferTypes.LOANS]: DealTypes.LOANS,
        [OfferTypes.EQUITY]: DealTypes.EQUITY,
        [OfferTypes.GRANTS]: DealTypes.GRANTS,
      };

      dispatch({
        type: ActionType.SET_DEAL_TYPE,
        payload: dealOfferTypeMap[initialProductType],
      });

      dispatch({
        type: ActionType.SET_DEAL_SUBTYPE,
        payload: initialProductSubtype ?? '',
      });
    }
  }, [isCreateDealOpen, initialProductType, initialProductSubtype]);

  useEffect(() => {
    if (!state.ownerList) return;
    findDefaultUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.ownerList]);

  useEffect(() => {
    if (!globalState.system.roles) return;
    fetchUsers(isFetchingUsers, globalState.system?.roles, setOwnerList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.system.roles]);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_DEAL_AMOUNT,
      payload: initialAmount ? initialAmount.toString() : '',
    });
  }, [initialAmount]);

  const closeModal = useCallback(() => {
    setIsCreateDealOpen(false);
  }, [setIsCreateDealOpen]);

  return (
    <div>
      <Modal show={isCreateDealOpen} handleClose={closeModal} testId={'create-deal'}>
        {state.isCreatingDeal ? (
          <div className={cn(styles['new-deal-loading-container'])}>
            <Spinner size="large" />
          </div>
        ) : (
          <div className={cn(styles['new-deal-modal'])}>
            <h3>{t('home:deals:createdealmodal:title')}</h3>
            <div className={cn(styles['deal-type-selector'])}>
              <h4>{t('home:deals:createdealmodal:fields:typelabel')}</h4>
              <div className={cn(styles['deal-type-buttons'])}>
                {dealsTypeList.map((type: TOption) => {
                  return (
                    <Button
                      ariaLabel={type.label}
                      key={type.label}
                      id={type.label}
                      className={cn(
                        styles['deal-type-button'],
                        state.dealType === type.value && styles['active']
                      )}
                      clickHandler={() => {
                        dispatch({
                          type: ActionType.SET_DEAL_TYPE,
                          payload: type.value,
                        });
                        dispatch({
                          type: ActionType.SET_DEAL_SUBTYPE,
                          payload: '',
                        });
                      }}
                    >
                      {capitalizeFirstLetter(type.label)}
                    </Button>
                  );
                })}
              </div>
            </div>
            {currentSubTypesDisplayed() && (
              <div className={cn(styles['deal-subtype-selector'])}>
                <h4>{t('home:deals:createdealmodal:fields:subtypelabel')}</h4>
                <Select
                  {...{
                    metadata: {
                      id: 'deal-subtype-dropdown',
                      placeholder: t('home:deals:createdealmodal:fields:subtypeplaceholder'),
                      options: currentSubTypesDisplayed(),
                      value: state.dealSubType,
                      required: true,
                      validationtype: ValidationTypes.SELECT,
                      classNames: { field: cn(styles['deal-subtype-dropdown']) },
                    },
                    errors: [],
                    handleChange: (e: any) => {
                      dispatch({
                        type: ActionType.SET_DEAL_SUBTYPE,
                        payload: e.target.value,
                      });
                    },
                  }}
                />
              </div>
            )}
            <div className={cn(styles['deal-stage-selector'])}>
              <h4>{t('home:deals:createdealmodal:fields:stagelabel')}</h4>
              <Select
                {...{
                  metadata: {
                    id: 'deal-stage-dropdown',
                    placeholder: t('home:deals:createdealmodal:fields:stageplaceholder'),
                    options: dealStatusListOptions,
                    value: state.dealStage,
                    required: true,
                    validationtype: ValidationTypes.SELECT,
                    classNames: { field: cn(styles['deal-stage-dropdown']) },
                  },
                  errors: [],
                  handleChange: (e: any) => {
                    dispatch({
                      type: ActionType.SET_DEAL_STAGE,
                      payload: e.target.value,
                    });
                  },
                }}
              />
            </div>
            <div className={cn(styles['deal-amount'])}>
              <h4>{t('home:deals:createdealmodal:fields:amountlabel')}</h4>
              <CurrencyInput
                onChange={(v) => {
                  dispatch({
                    type: ActionType.SET_DEAL_AMOUNT,
                    payload: v,
                  });
                }}
                placeHolder={t('home:deals:createdealmodal:fields:amountplaceholder')}
                value={state.dealAmount || ''}
                prefix={t('currency:symbol')}
              />
            </div>
            <div className={cn(styles['deal-owner'])}>
              <h4>{t('home:deals:createdealmodal:fields:ownerlabel')}</h4>
              <UserSelect
                selectedUser={state.selectedOwner}
                users={state.ownerList}
                loading={state.isFetchingUsers}
                onSelect={(owner: APIUsersByRoleType) => assignOwner(owner)}
                isNewDeal={true}
                className={cn(styles['deal-owner-select'])}
              />
            </div>
            <div className={cn(styles['actions-container'])}>
              <Button
                ariaLabel={'new-deal-cancel'}
                id={'new-deal-cancel'}
                buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
                clickHandler={closeModal}
              >
                {t('home:deals:createdealmodal:buttons:cancel')}
              </Button>
              <Button ariaLabel={'new-deal-start'} id={'new-deal-start'} clickHandler={createDeal}>
                {t('home:deals:createdealmodal:buttons:start')}
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CreateDealModal;
