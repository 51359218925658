import { DealTypes } from 'pages/deals/types';
import { CreatorType } from './types';

export const dealsTypeList = [
  {
    value: DealTypes.LOANS,
    label: 'Debt',
  },
  {
    value: DealTypes.EQUITY,
    label: 'Equity',
  },
  {
    value: DealTypes.GRANTS,
    label: 'Grant',
  },
  {
    value: DealTypes.TAX,
    label: 'Tax',
  },
  {
    value: DealTypes.INSURANCE,
    label: 'Insurance',
  },
  {
    value: DealTypes.FX,
    label: 'FX',
  },
  {
    value: DealTypes.PENSION,
    label: 'Pension',
  },
  {
    value: DealTypes.ENERGY,
    label: 'Energy',
  },
  {
    value: DealTypes.BANKACCOUNT,
    label: 'Bank Account',
  },
  {
    value: DealTypes.CREDITCARD,
    label: 'Credit Card',
  },
];

export const dealTypesWithoutSubtypesValue: string[] = [
  DealTypes.GRANTS,
  DealTypes.PENSION,
  DealTypes.ENERGY,
  DealTypes.CREDITCARD,
];

// @tonote BE cant handle a type without a subtype otherwise
export const dealTypeSubtypeBackendMappings: { [key: string]: string } = {
  [DealTypes.GRANTS]: '0',
  [DealTypes.PENSION]: '1005',
  [DealTypes.ENERGY]: '1006',
  [DealTypes.CREDITCARD]: '1007',
};

export const creatorTypeList = [
  {
    value: CreatorType.Client,
    label: 'Client',
  },
  {
    value: CreatorType.Broker,
    label: 'Broker',
  },
  {
    value: CreatorType.Advisor,
    label: 'Advisor',
  },
];
