import React, { useReducer, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import Table from 'components/table';
import styles from './assets/products.module.scss';
import { field } from './fields/products.field';
import { productsReducer, ActionType } from './store/products.reducer';
import useDebounce from '_shared/hooks/useDebounce';
import { getProducts } from '_shared/api/products';
import usePagination from '_shared/hooks/usePagination';
import Pagination from 'components/pagination';
import { useTranslation } from 'react-i18next';
import { IContext } from 'store/types';
import { useAppState } from 'store';
import { UPDATE_PRODUCTS } from 'store/products/types';
import { RoutePath } from '_shared/routes';
import { AmplitudeTrackingEnum, sendAmplitudeData } from 'config/amplitude';
import ProductsFilters from './components/filters';
import { SWOOP_GROUP_ID } from '_shared/utils/application';
import { availableProducts } from './store/utils';
import { ApplicationTabEnum } from 'pages/companyApplicationForm/types';

const Products: React.FC = () => {
  const { state: globalState, dispatch: globalDispatch }: IContext = useAppState();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const isSwoopGroupUser = globalState.system.groupId === SWOOP_GROUP_ID;

  const [state, dispatch] = useReducer(productsReducer, {
    redirectToReferrer: false,
    loading: false,
    from: location.state || { from: { pathname: '/' } },
    field: field([]),
    items: [],
    pageNumber: globalState.product.pageNumber || 0,
    filter: {
      onlyActive: globalState.product.filter.onlyActive,
      pageSize: globalState.product.filter?.pageSize || 20,
      type: globalState.product.filter?.type,
      subcategory: globalState.product.filter?.subcategory,
    },
    products: {
      data: field([]),
      loading: false,
      error: false,
      isEmpty: false,
    },
    searchProduct: globalState.product.productIndexState || '',
  });

  const paginationHook = usePagination(state.filter.pageSize, state.pageNumber);
  const debouncedSearchTerm = useDebounce(state.searchProduct, 500);
  const setGlobalPaginationState = (pageNumber: number) => {
    globalDispatch({
      type: UPDATE_PRODUCTS,
      payload: {
        ...globalState.product,
        pageNumber: pageNumber,
      },
    });
  };

  const getFilterQuery = () => {
    const selectedFilters: { [key: string]: any } = Object.entries(state.filter).reduce(
      (acc, [key, val]) => {
        if (val || key === 'onlyActive') {
          return { ...acc, [key]: val };
        }
        return acc;
      },
      {}
    );

    return {
      ...selectedFilters,
      ...((state.searchProduct && { productName: state.searchProduct }) || {}),
      ...((!isSwoopGroupUser && {
        type: !state.filter.type
          ? [ApplicationTabEnum.LOAN, ApplicationTabEnum.GRANT]
          : state.filter.type,
      }) ||
        {}),
      // page -1, as page 0 from api corrispondes to page 1
      pageNumber: paginationHook.currentPage - 1,
    };
  };

  const getProductList = async () => {
    try {
      dispatch({
        type: ActionType.SETPRODUCTS,
        payload: {
          products: { loading: true },
        },
      });
      const query = getFilterQuery();
      const { data } = await getProducts(query);
      const { results, paging } = data;
      paginationHook.setTotalCount(paging.totalCount);

      dispatch({
        type: ActionType.SETPRODUCTS,
        payload: {
          products: {
            data: field(availableProducts(results, isSwoopGroupUser) || []),
            loading: false,
          },
        },
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: ActionType.SETPRODUCTS,
        payload: {
          products: { loading: false, error: true },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    if (!state.loading) {
      getProductList();
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, state.filter, paginationHook.currentPage]);

  const handleOnSearchProduct = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const query = event.target.value;
    dispatch({
      type: ActionType.SETSEARCHPRODUCT,
      payload: query,
    });
    globalDispatch({
      type: UPDATE_PRODUCTS,
      payload: {
        ...globalState.product,
        pageNumber: 1,
        productIndexState: query,
      },
    });
    paginationHook.setCurrentPage(1);
  };

  const handleTypeFilter = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const query = event.target.value;
    dispatch({
      type: ActionType.SET_TYPE_FILTER,
      payload: { type: query },
    });
    dispatch({
      type: ActionType.SET_SUBTYPE_FILTER,
      payload: { subcategory: '' },
    });
    globalDispatch({
      type: UPDATE_PRODUCTS,
      payload: {
        ...globalState.product,
        filter: {
          ...globalState.product.filter,
          type: query,
          subcategory: '',
        },
      },
    });
    sendAmplitudeData(AmplitudeTrackingEnum.producttypefilter, { type: query });
  };

  const handleSubtypeFilter = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const query = event.target.value;
    dispatch({
      type: ActionType.SET_SUBTYPE_FILTER,
      payload: { subcategory: query },
    });
    globalDispatch({
      type: UPDATE_PRODUCTS,
      payload: {
        ...globalState.product,
        filter: {
          ...globalState.product.filter,
          subcategory: query,
        },
      },
    });
    sendAmplitudeData(AmplitudeTrackingEnum.productsubtypefilter, { subcategory: query });
  };

  const handlePageSize = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch({
      type: ActionType.SET_PAGE_SIZE,
      payload: { pageSize: event.target.value },
    });
  };

  const toggleEnabled = () => {
    dispatch({
      type: ActionType.TOGGLE_ENABLED,
      payload: '',
    });
    globalDispatch({
      type: UPDATE_PRODUCTS,
      payload: {
        ...globalState.product,
        pageNumber: 1,
        filter: {
          ...globalState.product.filter,
          onlyActive: !state.filter.onlyActive,
        },
      },
    });
    paginationHook.setCurrentPage(1);
  };

  const goToDetails = (id: string) => {
    if (!id) return;
    navigate(RoutePath.productinformation.replace(':id', id));
    sendAmplitudeData(AmplitudeTrackingEnum.viewproductinfo);
  };

  if (state.redirectToReferrer) {
    return <Navigate to={state.from} />;
  }

  const clearTypeAndSubtypeProductFilters = () => {
    dispatch({
      type: ActionType.SET_TYPE_FILTER,
      payload: { type: '' },
    });
    dispatch({
      type: ActionType.SET_SUBTYPE_FILTER,
      payload: { subcategory: '' },
    });
  };

  return (
    <div className={cn('wrapper', styles['products-container'])}>
      <div className={cn(styles.products)}>
        <ProductsFilters
          {...{
            toggleEnabled,
            handleOnSearchProduct,
            filter: {
              ...state.filter,
              totalCount: paginationHook.totalCount,
              searchProduct: state.searchProduct,
            },
            handleTypeFilter,
            handleSubtypeFilter,
            clearTypeAndSubtypeProductFilters,
          }}
        />
        <Table
          table-container={styles['table-container']}
          {...state.products?.data}
          loading={state.products.loading}
          rowCallBack={goToDetails}
          emptyMessage={t('home:products:search:empty')}
        />
        {!state.products.loading && (
          <div className={cn(styles.pagination)}>
            <Pagination
              {...{
                ...paginationHook,
                setGlobalPaginationState,
                handlePageSize,
                pageSize: state.filter.pageSize,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Products;
