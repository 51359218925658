import { SortBarType, SortOption } from './type';
import styles from './sortBar.module.scss';
import cn from 'classnames';
import Select from 'components/field/select';
import { OfferTypes } from '../../pages/fundingMatches/types';

const SortBar = ({
  productTypes,
  selectedOfferType,
  sortBy,
  sortOrder,
  selectedProductType,
  onProductTypeChange,
  onSortChange,
}: SortBarType) => {
  const handleSortChange = (newSortBy: SortOption) => {
    if (sortBy === newSortBy) {
      onSortChange(newSortBy, sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      onSortChange(newSortBy, 'asc');
    }
  };

  return (
    <>
      <div className={cn(styles['sort-bar-container'])} data-testid="sortBar">
        <div className={cn(styles['buttons'])}>
          <div>
            <span className={cn('material-icons', styles['icon'])}>sort</span>
            <span>Sort by</span>
          </div>
          <button
            className={cn(styles[sortBy === 'amount' ? 'btn-active' : 'btn'])}
            onClick={() => handleSortChange('amount')}
            data-testid="Amount"
          >
            <div className={cn(styles['label'])}>Amount</div>
            <div className={cn(styles['divider'])} />
            <span className={cn('material-icons', styles['icon'])}>
              {sortBy === 'amount' && sortOrder === 'asc' ? 'arrow_downward' : 'arrow_upward'}
            </span>
          </button>
          {selectedOfferType === OfferTypes.LOANS && (
            <button
              className={cn(styles[sortBy === 'rate' ? 'btn-active' : 'btn'])}
              onClick={() => handleSortChange('rate')}
              data-testid="Rate"
            >
              <div className={cn(styles['label'])}>Rate</div>
              <div className={cn(styles['divider'])} />
              <span className={cn('material-icons', styles['icon'])}>
                {sortBy === 'rate' && sortOrder === 'asc' ? 'arrow_downward' : 'arrow_upward'}{' '}
              </span>
            </button>
          )}
          <button
            className={cn(styles[sortBy === 'decisionTime' ? 'btn-active' : 'btn'])}
            onClick={() => handleSortChange('decisionTime')}
            data-testid="Decision Time"
          >
            <div className={cn(styles['label'])}>Decision Time</div>
            <div className={cn(styles['divider'])} />
            <span className={cn('material-icons', styles['icon'])}>
              {sortBy === 'decisionTime' && sortOrder === 'asc' ? 'arrow_downward' : 'arrow_upward'}
            </span>
          </button>
        </div>
        {selectedOfferType === 'Loans' && (
          <div className={cn(styles['dropdown'])}>
            <span className={cn('material-icons', styles['icon'])}>'filter_center_focus'</span>
            <Select
              {...{
                metadata: {
                  id: `product-type`,
                  placeholder: 'Product Type',
                  options: productTypes,
                  value: selectedProductType,
                },
                errors: [],
                handleChange: onProductTypeChange,
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SortBar;
