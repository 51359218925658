import { Role, UserRoles } from 'config/roles';
import { isAdvisorApplication, isBrokerApplication } from '_shared/utils/application';
import { getUsersByRole } from '_shared/api/roles';
import { DealTypes } from 'pages/deals/types';

export const fetchUsers = async (setLoading: Function, roles: Role[], setUserList: Function) => {
  setLoading(true);

  try {
    const role = roles?.find((role: Role) => {
      let roleName;

      if (isBrokerApplication) {
        roleName = UserRoles.BROKER;
      } else if (isAdvisorApplication) {
        roleName = UserRoles.ADVISOR;
      }
      return role.name === roleName;
    });
    if (!role?.id) return;
    const { data } = await getUsersByRole(role.id);

    setUserList(data.results);

    return data.results;
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false);
  }
};

export const calculateNetFee = (
  funderCommission: number,
  clientFee: number,
  introducerFee: number
) => {
  return funderCommission + clientFee - introducerFee;
};

export const convertType = (type: string) => {
  if (type === DealTypes.LOANS) return 'Loans';
  else if (type === DealTypes.EQUITY) return 'Equity';
  else if (type === DealTypes.GRANTS) return 'Grants';
};
