import i18n from 'config/i18n';
import { TCountry } from 'store/system/types';
import { env } from '_shared/utils';
import { TOption } from './form';
import { DealStatus } from 'pages/deals/types';

export const LSKeys = Object.freeze({
  prevBuildVersion: 'prevBuildVersion',
  xeroIntegrationPrevRoute: 'xeroIntegrationPrevRoute',
  validisIntegrationPrevRoute: 'validisIntegrationPrevRoute',
  truelayerIntegrationPrevRoute: 'truelayerIntegrationPrevRoute',
  bankIntegrationPrevRoute: 'bankIntegrationPrevRoute',
  bankIntegrationBankName: 'bankIntegrationBankName',
  productDetailsPrevRoute: 'productDetailsPrevRoute',
  swoopAnalytics: 'sw-bp-state',
  customMenu: 'customBPMenu',
});

export const tradingAgeRangesDic = Object.freeze({
  0: '0 - 6 months',
  6: '6 months - 1 year',
  12: '1 year - 2 years',
  24: '2 years - 5 years',
  60: '5 years - 7 years',
  84: '7 years - 10 years',
  121: '10 years +',
});

export const numberOfEmployeesDic = Object.freeze([
  {
    label: '1-25',
    value: '1-25',
  },
]);

export const businessTypeDic = Object.freeze([
  {
    label: 'Retail',
    value: 'retail',
  },
]);

export const companyScoreDic = Object.freeze([
  {
    label: 'Gold',
    value: 'gold',
  },
  {
    label: 'Silver',
    value: 'silver',
  },
  {
    label: 'Bronze',
    value: 'bronze',
  },
  {
    label: 'Copper',
    value: 'copper',
  },
]);

export const companyStageDic = Object.freeze([
  {
    label: 'Engagement',
    value: 'Engagement',
  },
  {
    label: 'Qualified',
    value: 'Qualified',
  },
  {
    label: 'Matching',
    value: 'Matching',
  },
  {
    label: 'Successful',
    value: 'Successful',
  },
  {
    label: 'Future',
    value: 'FUTURE',
  },
  {
    label: 'Dead',
    value: 'Dead',
  },
]);

export const productCategoryDic = Object.freeze([
  {
    label: 'Debt',
    value: 'DEBT',
  },
]);

export const productFundersDic = Object.freeze([
  {
    label: 'RBS',
    value: 'RBS',
  },
  {
    label: 'Danske',
    value: 'DANSKE',
  },
]);

export const applicationStageOptionsDic = Object.freeze([
  { label: 'Matched', value: 'SWOOP_REVIEW_MATCHED' },
  { label: 'Information Requested', value: 'SWOOP_REVIEW_INFORMATION_REQUESTED' },
  { label: 'Information Received', value: 'SWOOP_REVIEW_INFORMATION_RECEIVED' },
  { label: 'Present to Lender', value: 'PRESENT_TO_LENDER' },
  { label: 'Underwriting', value: 'UNDERWRITING' },
  { label: 'Additional information required', value: 'ADDITIONAL_INFORMATION_REQUIRED' },
  { label: 'Offer', value: 'OFFER' },
  { label: 'Offer Accepted', value: 'OFFER_ACCEPTED' },
  { label: 'Funded', value: 'FUNDED' },
  { label: 'Closed (Lost)', value: 'CLOSED_LOST' },
  { label: 'Rejected', value: 'SWOOP_REJECTED' },
]);

export const productSubCategoryDic = Object.freeze([
  { label: 'Invoice Finance', value: 'INVOICE_FINANCE' },
  { label: 'Supplier Finance', value: 'SUPPLIER_FINANCE' },
  { label: 'Order finance', value: 'ORDER_FINANCE' },
  { label: 'Asset Finance and Leasing', value: 'ASSET_FINANCE_AND_LEASING' },
  { label: 'Term Loan', value: 'TERM_LOAN' },
  { label: 'Working Capital Loan', value: 'WORKING_CAPITAL_LOAN' },
  { label: 'Merchant Finance', value: 'MERCHANT_FINANCE' },
  { label: 'Crowd Funding', value: 'CROWDFUNDING' },
  { label: 'MBO Finance', value: 'MBO_FINANCE' },
  { label: 'Overdraft', value: 'OVERDRAFT' },
  { label: 'Commercial Mortgage', value: 'COMMERCIAL_MORTGAGE' },
  { label: 'Credit Card', value: 'CREDIT_CARD' },
  { label: 'Other', value: 'OTHER' },
  { label: 'Angel', value: 'ANGEL' },
  { label: 'SEIS', value: 'SEIS' },
  { label: 'EIS', value: 'EIS' },
  { label: 'VCT', value: 'VCT' },
  { label: 'VC', value: 'VC' },
  { label: 'Family office', value: 'FAMILY_OFFICE' },
  { label: 'Private Equity', value: 'PRIVATE_EQUITY' },
]);

export const periodRangeDic = Object.freeze([
  { label: '0 - 6 months', value: 0 },
  { label: '6 months - 1 year', value: 6 },
  { label: '1 year - 2 years', value: 12 },
  { label: '2 years - 5 years', value: 24 },
  { label: '5 years - 7 years', value: 60 },
  { label: '7 years - 10 years', value: 84 },
  { label: '10 years +', value: 121 },
]);

export const shareHoldingPercentageDic = Object.freeze([
  {
    label: '100%',
    value: '100',
  },
]);

export const votingPercentage = Object.freeze([
  {
    label: '100%',
    value: '100',
  },
]);

export const formattedCountry = {
  unitedkingdom: 'United Kingdom',
  australia: 'Australia',
  canada: 'Canada',
}[env('REACT_APP_COUNTRY') as TCountry];

const banks: any = {
  unitedkingdom: [
    { label: 'AIB (UK)', value: 'AIB (UK)' },
    { label: 'AIB (IRE)', value: 'AIB (IRE)' },
    { label: 'Atom', value: 'Atom' },
    { label: 'Bank of Ireland (UK)', value: 'Bank of Ireland (UK)' },
    { label: 'Bank of Ireland (IRE)', value: 'Bank of Ireland (IRE)' },
    { label: 'Bank of Scotland', value: 'Bank of Scotland' },
    { label: 'Barclays', value: 'Barclays' },
    { label: 'Clydesdale Bank', value: 'Clydesdale Bank' },
    { label: 'Coconut', value: 'Coconut' },
    { label: 'Cynergy', value: 'Cynergy' },
    { label: 'C. Hoare & Co.', value: 'C. Hoare & Co.' },
    { label: 'Danske Bank (NI)', value: 'Danske Bank (NI)' },
    { label: 'First Direct', value: 'First Direct' },
    { label: 'First Trust (NI)', value: 'First Trust (NI)' },
    { label: 'Habib Bank Zurich', value: 'Habib Bank Zurich' },
    { label: 'KBC', value: 'KBC' },
    { label: 'HSBC', value: 'HSBC' },
    { label: 'Lloyds Bank', value: 'Lloyds Bank' },
    { label: 'M&S Bank', value: 'M&S Bank' },
    { label: 'Metro Bank', value: 'Metro Bank' },
    { label: 'Monzo', value: 'Monzo' },
    { label: 'NatWest', value: 'NatWest' },
    { label: 'RBS', value: 'RBS' },
    { label: 'Revolut', value: 'Revolut' },
    { label: 'Santander', value: 'Santander' },
    { label: 'Starling Bank', value: 'Starling Bank' },
    { label: 'The Co-operative Bank', value: 'The Co-operative Bank' },
    { label: 'Tide', value: 'Tide' },
    { label: 'TSB', value: 'TSB' },
    { label: 'Ulster Bank (UK)', value: 'Ulster Bank (UK)' },
    { label: 'Ulster Bank (IRE)', value: 'Ulster Bank (IRE)' },
    { label: 'Yorkshire Bank', value: 'Yorkshire Bank' },
    { label: 'Other', value: 'Other' },
  ],
  australia: [
    { label: 'Adelaide Bank', value: 'Adelaide Bank' },
    { label: 'ANZ', value: 'ANZ' },
    { label: 'Auswide Bank', value: 'Auswide Bank' },
    { label: 'AWA Alliance Bank', value: 'AWA Alliance Bank' },
    { label: 'Bank Australia', value: 'Bank Australia' },
    { label: 'Bank of China', value: 'Bank of China' },
    { label: 'Bank of Melbourne', value: 'Bank of Melbourne' },
    { label: 'Bank of Queensland', value: 'Bank of Queensland' },
    { label: 'Bank of Sydney', value: 'Bank of Sydney' },
    { label: 'Bank of Us', value: 'Bank of Us' },
    { label: 'BankSA', value: 'BankSA' },
    { label: 'Bankwest', value: 'Bankwest' },
    { label: 'Bendigo Bank', value: 'Bendigo Bank' },
    { label: 'Beyond Bank', value: 'Beyond Bank' },
    { label: 'Citibank', value: 'Citibank' },
    { label: 'Commonwealth Bank', value: 'Commonwealth Bank' },
    { label: 'Delphi Bank', value: 'Delphi Bank' },
    { label: 'Heritage', value: 'Heritage' },
    { label: 'HSBC', value: 'HSBC' },
    { label: 'IMB', value: 'IMB' },
    { label: 'ING Bank', value: 'ING Bank' },
    { label: 'Macquarie Bank', value: 'Macquarie Bank' },
    { label: 'National Australia Bank', value: 'National Australia Bank' },
    { label: 'Westpac', value: 'Westpac' },
    { label: 'Other', value: 'Other' },
  ],
  canada: [
    { label: 'ADS Canadian Bank', value: 'ADS Canadian Bank' },
    { label: 'B2B Bank', value: 'B2B Bank' },
    { label: 'BMO Financial Group', value: 'BMO Financial Group' },
    { label: 'The Bank of Nova Scotia', value: 'The Bank of Nova Scotia' },
    { label: 'Bridgewater Bank', value: 'Bridgewater Bank' },
    {
      label: 'Caisse populaire acadienne ltée (UNI Financial Cooperation)',
      value: 'Caisse populaire acadienne ltée (UNI Financial Cooperation)',
    },
    { label: 'CIBC', value: 'CIBC' },
    { label: 'Canadian Tire Bank', value: 'Canadian Tire Bank' },
    { label: 'Canadian Western Bank', value: 'Canadian Western Bank' },
    {
      label: 'Coast Capital Savings Federal Credit Union',
      value: 'Coast Capital Savings Federal Credit Union',
    },
    { label: 'Concentra Bank', value: 'Concentra Bank' },
    { label: 'CS Alterna Bank', value: 'CS Alterna Bank' },
    { label: 'Duo Bank of Canada', value: 'Duo Bank of Canada' },
    { label: 'Equitable Bank', value: 'Equitable Bank' },
    { label: 'Exchange Bank of Canada', value: 'Exchange Bank of Canada' },
    { label: 'General Bank of Canada', value: 'General Bank of Canada' },
    { label: 'Haventree Bank', value: 'Haventree Bank' },
    { label: 'Home Bank', value: 'Home Bank' },
    { label: 'HomeEquity Bank', value: 'HomeEquity Bank' },
    { label: 'Laurentian Bank of Canada', value: 'Laurentian Bank of Canada' },
    { label: 'Manulife Bank of Canada', value: 'Manulife Bank of Canada' },
    { label: 'Motusbank', value: 'Motusbank' },
    { label: 'National Bank of Canada', value: 'National Bank of Canada' },
    { label: 'Peoples Bank of Canada', value: 'Peoples Bank of Canada' },
    { label: "President's Choice Bank", value: "President's Choice Bank" },
    { label: 'RFA Bank of Canada', value: 'RFA Bank of Canada' },
    { label: 'Rogers Bank', value: 'Rogers Bank' },
    { label: 'Royal Bank of Canada', value: 'Royal Bank of Canada' },
    { label: 'Tangerine', value: 'Tangerine' },
    { label: 'TD Bank Group', value: 'TD Bank Group' },
    { label: 'Vancity Community Investment Bank', value: 'Vancity Community Investment Bank' },
    { label: 'VersaBank', value: 'VersaBank' },
    { label: 'Wealth One Bank of Canada', value: 'Wealth One Bank of Canada' },
    { label: 'Other', value: 'Other' },
  ],
};

export const banksDic = banks[env('REACT_APP_COUNTRY') as TCountry];

export const sectorsDic = Object.freeze([
  { label: 'Aerospace', value: 'Aerospace' },
  { label: 'Age care services', value: 'Age care services' },
  { label: 'Age care tech', value: 'Age care tech' },
  { label: 'Agriculture', value: 'Agriculture' },
  { label: 'Agritech', value: 'Agritech' },
  { label: 'Automotive', value: 'Automotive' },
  { label: 'Autonomous Vehicles', value: 'Autonomous Vehicles' },
  { label: 'Artificial Intelligence', value: 'Artificial Intelligence' },
  { label: 'Biotech', value: 'Biotech' },
  { label: 'Bots', value: 'Bots' },
  { label: 'Business Services', value: 'Business Services' },
  {
    label: 'Buying and selling of own real estate',
    value: 'Buying and selling of own real estate',
  },
  { label: 'Construction', value: 'Construction' },
  { label: 'Courier', value: 'Courier' },
  { label: 'Creative', value: 'Creative' },
  { label: 'Cybersecurity', value: 'Cybersecurity' },
  { label: 'Data Analytics/Data Science', value: 'Data Analytics or Data Science' },
  { label: 'Defense', value: 'Defense' },
  { label: 'Deep Technology', value: 'Deep Technology' },
  { label: 'Distribution', value: 'Distribution' },
  { label: 'Drink', value: 'Drink' },
  { label: 'Drones', value: 'Drones' },
  { label: 'E-commerce', value: 'Ecommerce' },
  { label: 'Education', value: 'Education' },
  { label: 'Energy', value: 'Energy' },
  { label: 'Engineering', value: 'Engineering' },
  { label: 'Entertainment', value: 'Entertainment' },
  { label: 'Fashion and accessories', value: 'Fashion and accessories' },
  { label: 'Film', value: 'Film' },
  { label: 'Financial Services', value: 'Financial Services' },
  { label: 'Fintech', value: 'Fintech' },
  { label: 'Food and Beverage', value: 'Food and Beverage' },
  { label: 'Government/Public Sector services', value: 'Government or Public Sector services' },
  { label: 'GovTech', value: 'GovTech' },
  { label: 'Haulage', value: 'Haulage' },
  { label: 'Healthcare', value: 'Healthcare' },
  { label: 'Hospitality', value: 'Hospitality' },
  { label: 'Hotels/public house/guest house', value: 'Hotels or public house or guest house' },
  { label: 'Insurance', value: 'Insurance' },
  { label: 'Insurtech', value: 'Insurtech' },
  { label: 'Import/export', value: 'Import export' },
  { label: 'IT', value: 'IT' },
  { label: 'Life Sciences', value: 'Life Sciences' },
  { label: 'Logistics', value: 'Logistics' },
  { label: 'Manufacturing', value: 'Manufacturing' },
  { label: 'Marketing Services', value: 'Marketing Services' },
  { label: 'Martech', value: 'Martech' },
  { label: 'Media', value: 'Media' },
  { label: 'Medtech', value: 'Medtech' },
  { label: 'Payment Processing', value: 'Payment Processing' },
  { label: 'Print/publising', value: 'Print publising' },
  { label: 'Professional Services', value: 'Professional Services' },
  { label: 'Property', value: 'Property' },
  { label: 'Proptech', value: 'Proptech' },
  { label: 'Recruitment Services', value: 'Recruitment Services' },
  { label: 'Recruitment Tech', value: 'Recruitment Tech' },
  { label: 'Retail', value: 'Retail' },
  { label: 'Renewable Energy', value: 'Renewable Energy' },
  { label: 'Robotics', value: 'Robotics' },
  {
    label: 'Sci & Tech Activities (excl Enviro & Quant Surveying)',
    value: 'Sci and Tech Activities excl Enviro and Quant Surveying',
  },
  { label: 'Security services', value: 'Security services' },
  { label: 'Social Enterprise', value: 'Social Enterprise' },
  { label: 'Sport', value: 'Sport' },
  { label: 'Sporttech', value: 'Sporttech' },
  { label: 'Technology', value: 'Technology' },
  { label: 'Trade', value: 'Trade' },
  { label: 'Transportation services', value: 'Transportation services' },
  { label: 'Transport Tech', value: 'Transport Tech' },
  { label: 'Wholesale', value: 'Wholesale' },
  { label: 'Other', value: 'Other' },
]);

export const selectionFieldTypes = ['radio', 'checkbox'];

export const customfields = ['autocomplete'];

export const applicationStageDic: { [key: string]: string } = {
  SWOOP_REVIEW_MATCHED: 'Matched',
  SWOOP_REVIEW_INFORMATION_REQUESTED: 'Info Requested',
  SWOOP_REVIEW_INFORMATION_RECEIVED: 'Info Received',
  PRESENT_TO_LENDER: 'Lender',
  UNDERWRITING: 'Underwriting',
  ADDITIONAL_INFORMATION_REQUIRED: 'Add-Info-Required',
  OFFER: 'Offer',
  OFFER_ACCEPTED: 'Offer Accepted',
  FUNDED: 'Funded',
  CLOSED_LOST: 'Closed (Lost)',
  SWOOP_REJECTED: 'Rejected',
};

export const businessModelsDic = [
  { label: 'B2C', value: 'B2C' },
  { label: 'B2B', value: 'B2B' },
  { label: 'B2B2C', value: 'B2B2C' },
  { label: 'socialEnterprise', value: 'socialEnterprise' },
];

export const titleOptionsDic = [
  { label: 'Mr', name: 'Mr', value: 'mr' },
  { label: 'Ms', name: 'Ms', value: 'ms' },
  { label: 'Mrs', name: 'Mrs', value: 'mrs' },
  { label: 'Miss', name: 'Miss', value: 'miss' },
  { label: 'Doctor', name: 'Doctor', value: 'doctor' },
  { label: 'Professor', name: 'Professor', value: 'professor' },
  { label: 'Sir', name: 'Sir', value: 'sir' },
  { label: 'Lord', name: 'Lord', value: 'lord' },
  { label: 'Lady', name: 'Lady', value: 'lady' },
  { label: 'Baron', name: 'Baron', value: 'baron' },
  { label: 'Baroness', name: 'Baroness', value: 'baroness' },
  { label: 'Rev', name: 'Rev', value: 'rev' },
];

export const genderOptionsDic = [
  { label: 'Female', value: 'Female' },
  { label: 'Male', value: 'Male' },
  { label: 'Prefer not to say', value: 'Prefer not to say' },
  { label: 'Other', value: 'Other' },
];

export const countriesDic = Object.freeze(
  Object.entries({
    GBR: 'United Kingdom',
    AUS: 'Australia',
    CAN: 'Canada',
    AFG: 'Afghanistan',
    ALB: 'Albania',
    DZA: 'Algeria',
    ASM: 'American Samoa',
    AND: 'Andorra',
    AGO: 'Angola',
    AIA: 'Anguilla',
    ATA: 'Antarctica',
    ATG: 'Antigua and Barbuda',
    ARG: 'Argentina',
    ARM: 'Armenia',
    ABW: 'Aruba',
    AUT: 'Austria',
    AZE: 'Azerbaijan',
    BHS: 'Bahamas (the)',
    BHR: 'Bahrain',
    BGD: 'Bangladesh',
    BRB: 'Barbados',
    BLR: 'Belarus',
    BEL: 'Belgium',
    BLZ: 'Belize',
    BEN: 'Benin',
    BMU: 'Bermuda',
    BTN: 'Bhutan',
    BOL: 'Bolivia (Plurinational State of)',
    BES: 'Bonaire, Sint Eustatius and Saba',
    BIH: 'Bosnia and Herzegovina',
    BWA: 'Botswana',
    BVT: 'Bouvet Island',
    BRA: 'Brazil',
    IOT: 'British Indian Ocean Territory (the)',
    BRN: 'Brunei Darussalam',
    BGR: 'Bulgaria',
    BFA: 'Burkina Faso',
    BDI: 'Burundi',
    CPV: 'Cabo Verde',
    KHM: 'Cambodia',
    CMR: 'Cameroon',
    CYM: 'Cayman Islands (the)',
    CAF: 'Central African Republic (the)',
    TCD: 'Chad',
    CHL: 'Chile',
    CHN: 'China',
    CXR: 'Christmas Island',
    CHI: 'Channel Islands',
    CCK: 'Cocos (Keeling) Islands (the)',
    COL: 'Colombia',
    COM: 'Comoros (the)',
    COD: 'Congo (the Democratic Republic of the)',
    COG: 'Congo (the)',
    COK: 'Cook Islands (the)',
    CRI: 'Costa Rica',
    HRV: 'Croatia',
    CUB: 'Cuba',
    CUW: 'Curaçao',
    CYP: 'Cyprus',
    CZE: 'Czechia',
    CIV: "Côte d'Ivoire",
    DNK: 'Denmark',
    DJI: 'Djibouti',
    DMA: 'Dominica',
    DOM: 'Dominican Republic (the)',
    ECU: 'Ecuador',
    EGY: 'Egypt',
    SLV: 'El Salvador',
    ENG: 'England',
    GNQ: 'Equatorial Guinea',
    ERI: 'Eritrea',
    EST: 'Estonia',
    SWZ: 'Eswatini',
    ETH: 'Ethiopia',
    FLK: 'Falkland Islands (the) [Malvinas]',
    FRO: 'Faroe Islands (the)',
    FJI: 'Fiji',
    FIN: 'Finland',
    FRA: 'France',
    GUF: 'French Guiana',
    PYF: 'French Polynesia',
    ATF: 'French Southern Territories (the)',
    GAB: 'Gabon',
    GMB: 'Gambia (the)',
    GEO: 'Georgia',
    DEU: 'Germany',
    GHA: 'Ghana',
    GIB: 'Gibraltar',
    GRC: 'Greece',
    GRL: 'Greenland',
    GRD: 'Grenada',
    GLP: 'Guadeloupe',
    GUM: 'Guam',
    GTM: 'Guatemala',
    GGY: 'Guernsey',
    GIN: 'Guinea',
    GNB: 'Guinea-Bissau',
    GUY: 'Guyana',
    HTI: 'Haiti',
    HMD: 'Heard Island and McDonald Islands',
    VAT: 'Holy See (the)',
    HND: 'Honduras',
    HKG: 'Hong Kong',
    HUN: 'Hungary',
    ISL: 'Iceland',
    IND: 'India',
    IDN: 'Indonesia',
    IRN: 'Iran (Islamic Republic of)',
    IRQ: 'Iraq',
    IRL: 'Ireland',
    IMN: 'Isle of Man',
    ISR: 'Israel',
    ITA: 'Italy',
    JAM: 'Jamaica',
    JPN: 'Japan',
    JEY: 'Jersey',
    JOR: 'Jordan',
    KAZ: 'Kazakhstan',
    KEN: 'Kenya',
    KIR: 'Kiribati',
    PRK: "Korea (the Democratic People's Republic of)",
    KOR: 'Korea (the Republic of)',
    KWT: 'Kuwait',
    KGZ: 'Kyrgyzstan',
    LAO: "Lao People's Democratic Republic (the)",
    LVA: 'Latvia',
    LBN: 'Lebanon',
    LSO: 'Lesotho',
    LBR: 'Liberia',
    LBY: 'Libya',
    LIE: 'Liechtenstein',
    LTU: 'Lithuania',
    LUX: 'Luxembourg',
    MAC: 'Macao',
    MDG: 'Madagascar',
    MWI: 'Malawi',
    MYS: 'Malaysia',
    MDV: 'Maldives',
    MLI: 'Mali',
    MLT: 'Malta',
    MHL: 'Marshall Islands (the)',
    MTQ: 'Martinique',
    MRT: 'Mauritania',
    MUS: 'Mauritius',
    MYT: 'Mayotte',
    MEX: 'Mexico',
    FSM: 'Micronesia (Federated States of)',
    MDA: 'Moldova (the Republic of)',
    MCO: 'Monaco',
    MNG: 'Mongolia',
    MNE: 'Montenegro',
    MSR: 'Montserrat',
    MAR: 'Morocco',
    MOZ: 'Mozambique',
    MMR: 'Myanmar',
    NAM: 'Namibia',
    NRU: 'Nauru',
    NPL: 'Nepal',
    NLD: 'Netherlands (the)',
    NCL: 'New Caledonia',
    NZL: 'New Zealand',
    NIC: 'Nicaragua',
    NER: 'Niger (the)',
    NGA: 'Nigeria',
    NIU: 'Niue',
    NFK: 'Norfolk Island',
    MNP: 'Northern Mariana Islands (the)',
    NOR: 'Norway',
    OMN: 'Oman',
    PAK: 'Pakistan',
    PLW: 'Palau',
    PSE: 'Palestine, State of',
    PAN: 'Panama',
    PNG: 'Papua New Guinea',
    PRY: 'Paraguay',
    PER: 'Peru',
    PHL: 'Philippines (the)',
    PCN: 'Pitcairn',
    POL: 'Poland',
    PRT: 'Portugal',
    PRI: 'Puerto Rico',
    QAT: 'Qatar',
    MKD: 'Republic of North Macedonia',
    ROU: 'Romania',
    RUS: 'Russian Federation (the)',
    RWA: 'Rwanda',
    REU: 'Réunion',
    BLM: 'Saint Barthélemy',
    SHN: 'Saint Helena, Ascension and Tristan da Cunha',
    KNA: 'Saint Kitts and Nevis',
    LCA: 'Saint Lucia',
    MAF: 'Saint Martin (French part)',
    SPM: 'Saint Pierre and Miquelon',
    VCT: 'Saint Vincent and the Grenadines',
    WSM: 'Samoa',
    SMR: 'San Marino',
    STP: 'Sao Tome and Principe',
    SAU: 'Saudi Arabia',
    SCO: 'Scotland',
    SEN: 'Senegal',
    SRB: 'Serbia',
    SYC: 'Seychelles',
    SLE: 'Sierra Leone',
    SGP: 'Singapore',
    SXM: 'Sint Maarten (Dutch part)',
    SVK: 'Slovakia',
    SVN: 'Slovenia',
    SLB: 'Solomon Islands',
    SOM: 'Somalia',
    ZAF: 'South Africa',
    SGS: 'South Georgia and the South Sandwich Islands',
    SSD: 'South Sudan',
    ESP: 'Spain',
    LKA: 'Sri Lanka',
    SDN: 'Sudan (the)',
    SUR: 'Suriname',
    SJM: 'Svalbard and Jan Mayen',
    SWE: 'Sweden',
    CHE: 'Switzerland',
    SYR: 'Syrian Arab Republic',
    TWN: 'Taiwan (Province of China)',
    TJK: 'Tajikistan',
    TZA: 'Tanzania, United Republic of',
    THA: 'Thailand',
    TLS: 'Timor-Leste',
    TGO: 'Togo',
    TKL: 'Tokelau',
    TON: 'Tonga',
    TTO: 'Trinidad and Tobago',
    TUN: 'Tunisia',
    TUR: 'Turkey',
    TKM: 'Turkmenistan',
    TCA: 'Turks and Caicos Islands (the)',
    TUV: 'Tuvalu',
    UGA: 'Uganda',
    UKR: 'Ukraine',
    ARE: 'United Arab Emirates (the)',
    UMI: 'United States Minor Outlying Islands (the)',
    USA: 'United States of America (the)',
    URY: 'Uruguay',
    UZB: 'Uzbekistan',
    VUT: 'Vanuatu',
    VEN: 'Venezuela (Bolivarian Republic of)',
    VNM: 'Viet Nam',
    VGB: 'Virgin Islands (British)',
    VIR: 'Virgin Islands (U.S.)',
    WAL: 'Wales',
    WLF: 'Wallis and Futuna',
    ESH: 'Western Sahara',
    YEM: 'Yemen',
    ZMB: 'Zambia',
    ZWE: 'Zimbabwe',
    ALA: 'Åland Islands',
  }).reduce((acc, [key, value]): any => {
    return [...acc, { label: value, value: key }];
  }, [])
);

export const countries = [
  { name: 'United Kingdom', demonym: 'British', cca2: 'UK', cca3: 'GBR' },
  { name: 'Ireland', demonym: 'Irish', cca2: 'IE', cca3: 'IRL' },
  { name: 'Afghanistan', demonym: 'Afghan', cca2: 'AF', cca3: 'AFG' },
  { name: 'Åland Islands', demonym: 'Ålandish', cca2: 'AX', cca3: 'ALA' },
  { name: 'Albania', demonym: 'Albanian', cca2: 'AL', cca3: 'ALB' },
  { name: 'Algeria', demonym: 'Algerian', cca2: 'DZ', cca3: 'DZA' },
  { name: 'American Samoa', demonym: 'American Samoan', cca2: 'AS', cca3: 'ASM' },
  { name: 'Andorra', demonym: 'Andorran', cca2: 'AD', cca3: 'AND' },
  { name: 'Angola', demonym: 'Angolan', cca2: 'AO', cca3: 'AGO' },
  { name: 'Anguilla', demonym: 'Anguillian', cca2: 'AI', cca3: 'AIA' },
  { name: 'Antarctica', demonym: 'Antarctican', cca2: 'AQ', cca3: 'ATA' },
  { name: 'Antigua and Barbuda', demonym: 'Antiguan, Barbudan', cca2: 'AG', cca3: 'ATG' },
  { name: 'Argentina', demonym: 'Argentine', cca2: 'AR', cca3: 'ARG' },
  { name: 'Armenia', demonym: 'Armenian', cca2: 'AM', cca3: 'ARM' },
  { name: 'Aruba', demonym: 'Aruban', cca2: 'AW', cca3: 'ABW' },
  { name: 'Australia', demonym: 'Australian', cca2: 'AU', cca3: 'AUS' },
  { name: 'Austria', demonym: 'Austrian', cca2: 'AT', cca3: 'AUT' },
  { name: 'Azerbaijan', demonym: 'Azerbaijani', cca2: 'AZ', cca3: 'AZE' },
  { name: 'Bahamas', demonym: 'Bahamian', cca2: 'BS', cca3: 'BHS' },
  { name: 'Bahrain', demonym: 'Bahraini', cca2: 'BH', cca3: 'BHR' },
  { name: 'Bangladesh', demonym: 'Bangladeshi', cca2: 'BD', cca3: 'BGD' },
  { name: 'Barbados', demonym: 'Barbadian', cca2: 'BB', cca3: 'BRB' },
  { name: 'Belarus', demonym: 'Belarusian', cca2: 'BY', cca3: 'BLR' },
  { name: 'Belgium', demonym: 'Belgian', cca2: 'BE', cca3: 'BEL' },
  { name: 'Belize', demonym: 'Belizean', cca2: 'BZ', cca3: 'BLZ' },
  { name: 'Benin', demonym: 'Beninese', cca2: 'BJ', cca3: 'BEN' },
  { name: 'Bermuda', demonym: 'Bermudian', cca2: 'BM', cca3: 'BMU' },
  { name: 'Bhutan', demonym: 'Bhutanese', cca2: 'BT', cca3: 'BTN' },
  { name: 'Bolivia', demonym: 'Bolivian', cca2: 'BO', cca3: 'BOL' },
  { name: 'Bosnia and Herzegovina', demonym: 'Bosnian, Herzegovinian', cca2: 'BA', cca3: 'BIH' },
  { name: 'Botswana', demonym: 'Motswana', cca2: 'BW', cca3: 'BWA' },
  { name: 'Bouvet Island', demonym: '', cca2: 'BV', cca3: 'BVT' },
  { name: 'Brazil', demonym: 'Brazilian', cca2: 'BR', cca3: 'BRA' },
  { name: 'British Indian Ocean Territory', demonym: 'Indian', cca2: 'IO', cca3: 'IOT' },
  { name: 'British Virgin Islands', demonym: 'Virgin Islander', cca2: 'VG', cca3: 'VGB' },
  { name: 'Brunei', demonym: 'Bruneian', cca2: 'BN', cca3: 'BRN' },
  { name: 'Bulgaria', demonym: 'Bulgarian', cca2: 'BG', cca3: 'BGR' },
  { name: 'Burkina Faso', demonym: 'Burkinabe', cca2: 'BF', cca3: 'BFA' },
  { name: 'Burundi', demonym: 'Burundian', cca2: 'BI', cca3: 'BDI' },
  { name: 'Cambodia', demonym: 'Cambodian', cca2: 'KH', cca3: 'KHM' },
  { name: 'Cameroon', demonym: 'Cameroonian', cca2: 'CM', cca3: 'CMR' },
  { name: 'Canada', demonym: 'Canadian', cca2: 'CA', cca3: 'CAN' },
  { name: 'Cape Verde', demonym: 'Cape Verdian', cca2: 'CV', cca3: 'CPV' },
  { name: 'Caribbean Netherlands', demonym: 'Dutch', cca2: 'BQ', cca3: 'BES' },
  { name: 'Cayman Islands', demonym: 'Caymanian', cca2: 'KY', cca3: 'CYM' },
  { name: 'Central African Republic', demonym: 'Central African', cca2: 'CF', cca3: 'CAF' },
  { name: 'Chad', demonym: 'Chadian', cca2: 'TD', cca3: 'TCD' },
  { name: 'Chile', demonym: 'Chilean', cca2: 'CL', cca3: 'CHL' },
  { name: 'China', demonym: 'Chinese', cca2: 'CN', cca3: 'CHN' },
  { name: 'Christmas Island', demonym: 'Christmas Islander', cca2: 'CX', cca3: 'CXR' },
  { name: 'Cocos (Keeling) Islands', demonym: 'Cocos Islander', cca2: 'CC', cca3: 'CCK' },
  { name: 'Colombia', demonym: 'Colombian', cca2: 'CO', cca3: 'COL' },
  { name: 'Comoros', demonym: 'Comoran', cca2: 'KM', cca3: 'COM' },
  { name: 'Cook Islands', demonym: 'Cook Islander', cca2: 'CK', cca3: 'COK' },
  { name: 'Costa Rica', demonym: 'Costa Rican', cca2: 'CR', cca3: 'CRI' },
  { name: 'Croatia', demonym: 'Croatian', cca2: 'HR', cca3: 'HRV' },
  { name: 'Cuba', demonym: 'Cuban', cca2: 'CU', cca3: 'CUB' },
  { name: 'Curaçao', demonym: 'Dutch', cca2: 'CW', cca3: 'CUW' },
  { name: 'Cyprus', demonym: 'Cypriot', cca2: 'CY', cca3: 'CYP' },
  { name: 'Czechia', demonym: 'Czech', cca2: 'CZ', cca3: 'CZE' },
  { name: 'Denmark', demonym: 'Danish', cca2: 'DK', cca3: 'DNK' },
  { name: 'Djibouti', demonym: 'Djibouti', cca2: 'DJ', cca3: 'DJI' },
  { name: 'Dominica', demonym: 'Dominican', cca2: 'DM', cca3: 'DMA' },
  { name: 'Dominican Republic', demonym: 'Dominican', cca2: 'DO', cca3: 'DOM' },
  { name: 'DR Congo', demonym: 'Congolese', cca2: 'CD', cca3: 'COD' },
  { name: 'Ecuador', demonym: 'Ecuadorean', cca2: 'EC', cca3: 'ECU' },
  { name: 'Egypt', demonym: 'Egyptian', cca2: 'EG', cca3: 'EGY' },
  { name: 'El Salvador', demonym: 'Salvadoran', cca2: 'SV', cca3: 'SLV' },
  { name: 'Equatorial Guinea', demonym: 'Equatorial Guinean', cca2: 'GQ', cca3: 'GNQ' },
  { name: 'Eritrea', demonym: 'Eritrean', cca2: 'ER', cca3: 'ERI' },
  { name: 'Estonia', demonym: 'Estonian', cca2: 'EE', cca3: 'EST' },
  { name: 'Eswatini', demonym: 'Swazi', cca2: 'SZ', cca3: 'SWZ' },
  { name: 'Ethiopia', demonym: 'Ethiopian', cca2: 'ET', cca3: 'ETH' },
  { name: 'Falkland Islands', demonym: 'Falkland Islander', cca2: 'FK', cca3: 'FLK' },
  { name: 'Faroe Islands', demonym: 'Faroese', cca2: 'FO', cca3: 'FRO' },
  { name: 'Fiji', demonym: 'Fijian', cca2: 'FJ', cca3: 'FJI' },
  { name: 'Finland', demonym: 'Finnish', cca2: 'FI', cca3: 'FIN' },
  { name: 'France', demonym: 'French', cca2: 'FR', cca3: 'FRA' },
  { name: 'French Guiana', demonym: 'Guianan', cca2: 'GF', cca3: 'GUF' },
  { name: 'French Polynesia', demonym: 'French Polynesian', cca2: 'PF', cca3: 'PYF' },
  { name: 'French Southern and Antarctic Lands', demonym: 'French', cca2: 'TF', cca3: 'ATF' },
  { name: 'Gabon', demonym: 'Gabonese', cca2: 'GA', cca3: 'GAB' },
  { name: 'Gambia', demonym: 'Gambian', cca2: 'GM', cca3: 'GMB' },
  { name: 'Georgia', demonym: 'Georgian', cca2: 'GE', cca3: 'GEO' },
  { name: 'Germany', demonym: 'German', cca2: 'DE', cca3: 'DEU' },
  { name: 'Ghana', demonym: 'Ghanaian', cca2: 'GH', cca3: 'GHA' },
  { name: 'Gibraltar', demonym: 'Gibraltar', cca2: 'GI', cca3: 'GIB' },
  { name: 'Greece', demonym: 'Greek', cca2: 'GR', cca3: 'GRC' },
  { name: 'Greenland', demonym: 'Greenlandic', cca2: 'GL', cca3: 'GRL' },
  { name: 'Grenada', demonym: 'Grenadian', cca2: 'GD', cca3: 'GRD' },
  { name: 'Guadeloupe', demonym: 'Guadeloupian', cca2: 'GP', cca3: 'GLP' },
  { name: 'Guam', demonym: 'Guamanian', cca2: 'GU', cca3: 'GUM' },
  { name: 'Guatemala', demonym: 'Guatemalan', cca2: 'GT', cca3: 'GTM' },
  { name: 'Guernsey', demonym: 'Channel Islander', cca2: 'GG', cca3: 'GGY' },
  { name: 'Guinea', demonym: 'Guinean', cca2: 'GN', cca3: 'GIN' },
  { name: 'Guinea-Bissau', demonym: 'Guinea-Bissauan', cca2: 'GW', cca3: 'GNB' },
  { name: 'Guyana', demonym: 'Guyanese', cca2: 'GY', cca3: 'GUY' },
  { name: 'Haiti', demonym: 'Haitian', cca2: 'HT', cca3: 'HTI' },
  {
    name: 'Heard Island and McDonald Islands',
    demonym: 'Heard and McDonald Islander',
    cca2: 'HM',
    cca3: 'HMD',
  },
  { name: 'Honduras', demonym: 'Honduran', cca2: 'HN', cca3: 'HND' },
  { name: 'Hong Kong', demonym: 'Hong Konger', cca2: 'HK', cca3: 'HKG' },
  { name: 'Hungary', demonym: 'Hungarian', cca2: 'HU', cca3: 'HUN' },
  { name: 'Iceland', demonym: 'Icelander', cca2: 'IS', cca3: 'ISL' },
  { name: 'India', demonym: 'Indian', cca2: 'IN', cca3: 'IND' },
  { name: 'Indonesia', demonym: 'Indonesian', cca2: 'ID', cca3: 'IDN' },
  { name: 'Iran', demonym: 'Iranian', cca2: 'IR', cca3: 'IRN' },
  { name: 'Iraq', demonym: 'Iraqi', cca2: 'IQ', cca3: 'IRQ' },
  { name: 'Isle of Man', demonym: 'Manx', cca2: 'IM', cca3: 'IMN' },
  { name: 'Israel', demonym: 'Israeli', cca2: 'IL', cca3: 'ISR' },
  { name: 'Italy', demonym: 'Italian', cca2: 'IT', cca3: 'ITA' },
  { name: 'Ivory Coast', demonym: 'Ivorian', cca2: 'CI', cca3: 'CIV' },
  { name: 'Jamaica', demonym: 'Jamaican', cca2: 'JM', cca3: 'JAM' },
  { name: 'Japan', demonym: 'Japanese', cca2: 'JP', cca3: 'JPN' },
  { name: 'Jersey', demonym: 'Channel Islander', cca2: 'JE', cca3: 'JEY' },
  { name: 'Jordan', demonym: 'Jordanian', cca2: 'JO', cca3: 'JOR' },
  { name: 'Kazakhstan', demonym: 'Kazakhstani', cca2: 'KZ', cca3: 'KAZ' },
  { name: 'Kenya', demonym: 'Kenyan', cca2: 'KE', cca3: 'KEN' },
  { name: 'Kiribati', demonym: 'I-Kiribati', cca2: 'KI', cca3: 'KIR' },
  { name: 'Kosovo', demonym: 'Kosovar', cca2: 'XK', cca3: 'UNK' },
  { name: 'Kuwait', demonym: 'Kuwaiti', cca2: 'KW', cca3: 'KWT' },
  { name: 'Kyrgyzstan', demonym: 'Kirghiz', cca2: 'KG', cca3: 'KGZ' },
  { name: 'Laos', demonym: 'Laotian', cca2: 'LA', cca3: 'LAO' },
  { name: 'Latvia', demonym: 'Latvian', cca2: 'LV', cca3: 'LVA' },
  { name: 'Lebanon', demonym: 'Lebanese', cca2: 'LB', cca3: 'LBN' },
  { name: 'Lesotho', demonym: 'Mosotho', cca2: 'LS', cca3: 'LSO' },
  { name: 'Liberia', demonym: 'Liberian', cca2: 'LR', cca3: 'LBR' },
  { name: 'Libya', demonym: 'Libyan', cca2: 'LY', cca3: 'LBY' },
  { name: 'Liechtenstein', demonym: 'Liechtensteiner', cca2: 'LI', cca3: 'LIE' },
  { name: 'Lithuania', demonym: 'Lithuanian', cca2: 'LT', cca3: 'LTU' },
  { name: 'Luxembourg', demonym: 'Luxembourger', cca2: 'LU', cca3: 'LUX' },
  { name: 'Macau', demonym: 'Macanese', cca2: 'MO', cca3: 'MAC' },
  { name: 'Macedonia', demonym: 'Macedonian', cca2: 'MK', cca3: 'MKD' },
  { name: 'Madagascar', demonym: 'Malagasy', cca2: 'MG', cca3: 'MDG' },
  { name: 'Malawi', demonym: 'Malawian', cca2: 'MW', cca3: 'MWI' },
  { name: 'Malaysia', demonym: 'Malaysian', cca2: 'MY', cca3: 'MYS' },
  { name: 'Maldives', demonym: 'Maldivan', cca2: 'MV', cca3: 'MDV' },
  { name: 'Mali', demonym: 'Malian', cca2: 'ML', cca3: 'MLI' },
  { name: 'Malta', demonym: 'Maltese', cca2: 'MT', cca3: 'MLT' },
  { name: 'Marshall Islands', demonym: 'Marshallese', cca2: 'MH', cca3: 'MHL' },
  { name: 'Martinique', demonym: 'French', cca2: 'MQ', cca3: 'MTQ' },
  { name: 'Mauritania', demonym: 'Mauritanian', cca2: 'MR', cca3: 'MRT' },
  { name: 'Mauritius', demonym: 'Mauritian', cca2: 'MU', cca3: 'MUS' },
  { name: 'Mayotte', demonym: 'Mahoran', cca2: 'YT', cca3: 'MYT' },
  { name: 'Mexico', demonym: 'Mexican', cca2: 'MX', cca3: 'MEX' },
  { name: 'Micronesia', demonym: 'Micronesian', cca2: 'FM', cca3: 'FSM' },
  { name: 'Moldova', demonym: 'Moldovan', cca2: 'MD', cca3: 'MDA' },
  { name: 'Monaco', demonym: 'Monegasque', cca2: 'MC', cca3: 'MCO' },
  { name: 'Mongolia', demonym: 'Mongolian', cca2: 'MN', cca3: 'MNG' },
  { name: 'Montenegro', demonym: 'Montenegrin', cca2: 'ME', cca3: 'MNE' },
  { name: 'Montserrat', demonym: 'Montserratian', cca2: 'MS', cca3: 'MSR' },
  { name: 'Morocco', demonym: 'Moroccan', cca2: 'MA', cca3: 'MAR' },
  { name: 'Mozambique', demonym: 'Mozambican', cca2: 'MZ', cca3: 'MOZ' },
  { name: 'Myanmar', demonym: 'Bamar', cca2: 'MM', cca3: 'MMR' },
  { name: 'Namibia', demonym: 'Namibian', cca2: 'NA', cca3: 'NAM' },
  { name: 'Nauru', demonym: 'Nauruan', cca2: 'NR', cca3: 'NRU' },
  { name: 'Nepal', demonym: 'Nepalese', cca2: 'NP', cca3: 'NPL' },
  { name: 'Netherlands', demonym: 'Dutch', cca2: 'NL', cca3: 'NLD' },
  { name: 'New Caledonia', demonym: 'New Caledonian', cca2: 'NC', cca3: 'NCL' },
  { name: 'New Zealand', demonym: 'New Zealander', cca2: 'NZ', cca3: 'NZL' },
  { name: 'Nicaragua', demonym: 'Nicaraguan', cca2: 'NI', cca3: 'NIC' },
  { name: 'Niger', demonym: 'Nigerien', cca2: 'NE', cca3: 'NER' },
  { name: 'Nigeria', demonym: 'Nigerian', cca2: 'NG', cca3: 'NGA' },
  { name: 'Niue', demonym: 'Niuean', cca2: 'NU', cca3: 'NIU' },
  { name: 'Norfolk Island', demonym: 'Norfolk Islander', cca2: 'NF', cca3: 'NFK' },
  { name: 'North Korea', demonym: 'North Korean', cca2: 'KP', cca3: 'PRK' },
  { name: 'Northern Mariana Islands', demonym: 'American', cca2: 'MP', cca3: 'MNP' },
  { name: 'Norway', demonym: 'Norwegian', cca2: 'NO', cca3: 'NOR' },
  { name: 'Oman', demonym: 'Omani', cca2: 'OM', cca3: 'OMN' },
  { name: 'Pakistan', demonym: 'Pakistani', cca2: 'PK', cca3: 'PAK' },
  { name: 'Palau', demonym: 'Palauan', cca2: 'PW', cca3: 'PLW' },
  { name: 'Palestine', demonym: 'Palestinian', cca2: 'PS', cca3: 'PSE' },
  { name: 'Panama', demonym: 'Panamanian', cca2: 'PA', cca3: 'PAN' },
  { name: 'Papua New Guinea', demonym: 'Papua New Guinean', cca2: 'PG', cca3: 'PNG' },
  { name: 'Paraguay', demonym: 'Paraguayan', cca2: 'PY', cca3: 'PRY' },
  { name: 'Peru', demonym: 'Peruvian', cca2: 'PE', cca3: 'PER' },
  { name: 'Philippines', demonym: 'Filipino', cca2: 'PH', cca3: 'PHL' },
  { name: 'Pitcairn Islands', demonym: 'Pitcairn Islander', cca2: 'PN', cca3: 'PCN' },
  { name: 'Poland', demonym: 'Polish', cca2: 'PL', cca3: 'POL' },
  { name: 'Portugal', demonym: 'Portuguese', cca2: 'PT', cca3: 'PRT' },
  { name: 'Puerto Rico', demonym: 'Puerto Rican', cca2: 'PR', cca3: 'PRI' },
  { name: 'Qatar', demonym: 'Qatari', cca2: 'QA', cca3: 'QAT' },
  { name: 'Republic of the Congo', demonym: 'Congolese', cca2: 'CG', cca3: 'COG' },
  { name: 'Réunion', demonym: 'French', cca2: 'RE', cca3: 'REU' },
  { name: 'Romania', demonym: 'Romanian', cca2: 'RO', cca3: 'ROU' },
  { name: 'Russia', demonym: 'Russian', cca2: 'RU', cca3: 'RUS' },
  { name: 'Rwanda', demonym: 'Rwandan', cca2: 'RW', cca3: 'RWA' },
  { name: 'Saint Barthélemy', demonym: 'Saint Barthélemy Islander', cca2: 'BL', cca3: 'BLM' },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    demonym: 'Saint Helenian',
    cca2: 'SH',
    cca3: 'SHN',
  },
  { name: 'Saint Kitts and Nevis', demonym: 'Kittitian or Nevisian', cca2: 'KN', cca3: 'KNA' },
  { name: 'Saint Lucia', demonym: 'Saint Lucian', cca2: 'LC', cca3: 'LCA' },
  { name: 'Saint Martin', demonym: 'Saint Martin Islander', cca2: 'MF', cca3: 'MAF' },
  { name: 'Saint Pierre and Miquelon', demonym: 'French', cca2: 'PM', cca3: 'SPM' },
  {
    name: 'Saint Vincent and the Grenadines',
    demonym: 'Saint Vincentian',
    cca2: 'VC',
    cca3: 'VCT',
  },
  { name: 'Samoa', demonym: 'Samoan', cca2: 'WS', cca3: 'WSM' },
  { name: 'San Marino', demonym: 'Sammarinese', cca2: 'SM', cca3: 'SMR' },
  { name: 'São Tomé and Príncipe', demonym: 'Sao Tomean', cca2: 'ST', cca3: 'STP' },
  { name: 'Saudi Arabia', demonym: 'Saudi Arabian', cca2: 'SA', cca3: 'SAU' },
  { name: 'Senegal', demonym: 'Senegalese', cca2: 'SN', cca3: 'SEN' },
  { name: 'Serbia', demonym: 'Serbian', cca2: 'RS', cca3: 'SRB' },
  { name: 'Seychelles', demonym: 'Seychellois', cca2: 'SC', cca3: 'SYC' },
  { name: 'Sierra Leone', demonym: 'Sierra Leonean', cca2: 'SL', cca3: 'SLE' },
  { name: 'Singapore', demonym: 'Singaporean', cca2: 'SG', cca3: 'SGP' },
  { name: 'Sint Maarten', demonym: 'St. Maartener', cca2: 'SX', cca3: 'SXM' },
  { name: 'Slovakia', demonym: 'Slovak', cca2: 'SK', cca3: 'SVK' },
  { name: 'Slovenia', demonym: 'Slovene', cca2: 'SI', cca3: 'SVN' },
  { name: 'Solomon Islands', demonym: 'Solomon Islander', cca2: 'SB', cca3: 'SLB' },
  { name: 'Somalia', demonym: 'Somali', cca2: 'SO', cca3: 'SOM' },
  { name: 'South Africa', demonym: 'South African', cca2: 'ZA', cca3: 'ZAF' },
  {
    name: 'South Georgia',
    demonym: 'South Georgian South Sandwich Islander',
    cca2: 'GS',
    cca3: 'SGS',
  },
  { name: 'South Korea', demonym: 'South Korean', cca2: 'KR', cca3: 'KOR' },
  { name: 'South Sudan', demonym: 'South Sudanese', cca2: 'SS', cca3: 'SSD' },
  { name: 'Spain', demonym: 'Spanish', cca2: 'ES', cca3: 'ESP' },
  { name: 'Sri Lanka', demonym: 'Sri Lankan', cca2: 'LK', cca3: 'LKA' },
  { name: 'Sudan', demonym: 'Sudanese', cca2: 'SD', cca3: 'SDN' },
  { name: 'Suriname', demonym: 'Surinamer', cca2: 'SR', cca3: 'SUR' },
  { name: 'Svalbard and Jan Mayen', demonym: 'Norwegian', cca2: 'SJ', cca3: 'SJM' },
  { name: 'Sweden', demonym: 'Swedish', cca2: 'SE', cca3: 'SWE' },
  { name: 'Switzerland', demonym: 'Swiss', cca2: 'CH', cca3: 'CHE' },
  { name: 'Syria', demonym: 'Syrian', cca2: 'SY', cca3: 'SYR' },
  { name: 'Taiwan', demonym: 'Taiwanese', cca2: 'TW', cca3: 'TWN' },
  { name: 'Tajikistan', demonym: 'Tadzhik', cca2: 'TJ', cca3: 'TJK' },
  { name: 'Tanzania', demonym: 'Tanzanian', cca2: 'TZ', cca3: 'TZA' },
  { name: 'Thailand', demonym: 'Thai', cca2: 'TH', cca3: 'THA' },
  { name: 'Timor-Leste', demonym: 'East Timorese', cca2: 'TL', cca3: 'TLS' },
  { name: 'Togo', demonym: 'Togolese', cca2: 'TG', cca3: 'TGO' },
  { name: 'Tokelau', demonym: 'Tokelauan', cca2: 'TK', cca3: 'TKL' },
  { name: 'Tonga', demonym: 'Tongan', cca2: 'TO', cca3: 'TON' },
  { name: 'Trinidad and Tobago', demonym: 'Trinidadian', cca2: 'TT', cca3: 'TTO' },
  { name: 'Tunisia', demonym: 'Tunisian', cca2: 'TN', cca3: 'TUN' },
  { name: 'Turkey', demonym: 'Turkish', cca2: 'TR', cca3: 'TUR' },
  { name: 'Turkmenistan', demonym: 'Turkmen', cca2: 'TM', cca3: 'TKM' },
  {
    name: 'Turks and Caicos Islands',
    demonym: 'Turks and Caicos Islander',
    cca2: 'TC',
    cca3: 'TCA',
  },
  { name: 'Tuvalu', demonym: 'Tuvaluan', cca2: 'TV', cca3: 'TUV' },
  { name: 'Uganda', demonym: 'Ugandan', cca2: 'UG', cca3: 'UGA' },
  { name: 'Ukraine', demonym: 'Ukrainian', cca2: 'UA', cca3: 'UKR' },
  { name: 'United Arab Emirates', demonym: 'Emirati', cca2: 'AE', cca3: 'ARE' },
  { name: 'United States of America', demonym: 'American', cca2: 'US', cca3: 'USA' },
  { name: 'United States Minor Outlying Islands', demonym: 'American', cca2: 'UM', cca3: 'UMI' },
  { name: 'United States Virgin Islands', demonym: 'Virgin Islander', cca2: 'VI', cca3: 'VIR' },
  { name: 'Uruguay', demonym: 'Uruguayan', cca2: 'UY', cca3: 'URY' },
  { name: 'Uzbekistan', demonym: 'Uzbekistani', cca2: 'UZ', cca3: 'UZB' },
  { name: 'Vanuatu', demonym: 'Ni-Vanuatu', cca2: 'VU', cca3: 'VUT' },
  { name: 'Vatican City', demonym: 'Vatican', cca2: 'VA', cca3: 'VAT' },
  { name: 'Venezuela', demonym: 'Venezuelan', cca2: 'VE', cca3: 'VEN' },
  { name: 'Vietnam', demonym: 'Vietnamese', cca2: 'VN', cca3: 'VNM' },
  { name: 'Wallis and Futuna', demonym: 'Wallis and Futuna Islander', cca2: 'WF', cca3: 'WLF' },
  { name: 'Western Sahara', demonym: 'Sahrawi', cca2: 'EH', cca3: 'ESH' },
  { name: 'Yemen', demonym: 'Yemeni', cca2: 'YE', cca3: 'YEM' },
  { name: 'Zambia', demonym: 'Zambian', cca2: 'ZM', cca3: 'ZMB' },
  { name: 'Zimbabwe', demonym: 'Zimbabwean', cca2: 'ZW', cca3: 'ZWE' },
];

export const stateAndNationDic = Object.freeze(
  Object.entries({
    ENG: 'England',
    CHI: 'Channel Islands',
    IMN: 'Isle of Man',
    IRE: 'Ireland',
    SCO: 'Scotland',
    WAL: 'Wales',
    NSW: 'NSW',
    QLD: 'QLD',
    VIC: 'VIC',
    SA: 'SA',
    NT: 'NT',
    TAS: 'TAS',
    ACT: 'ACT',
    WA: 'WA',
    ALB: 'Alberta',
    BCL: 'British Columbia',
    MTB: 'Manitoba',
    NBW: 'New Brunswick',
    NFL: 'Newfoundland and Labrador',
    NWT: 'Northwest Territories',
    NSC: 'Nova Scotia',
    NNV: 'Nunavut',
    ONT: 'Ontario',
    PEI: 'Prince Edward Island',
    QBC: 'Quebec',
    SKW: 'Saskatchewan',
    YUK: 'Yukon',
  }).reduce((acc, [key, value]): any => {
    return [...acc, { label: value, value: key }];
  }, [])
);

export const districtsLookup = [
  {
    code: 'England',
    values: [
      'Hartlepool',
      'Middlesbrough',
      'Redcar and Cleveland',
      'Stockton-on-Tees',
      'Darlington',
      'Halton',
      'Warrington',
      'Blackburn with Darwen',
      'Blackpool',
      'Kingston upon Hull, City of',
      'East Riding of Yorkshire',
      'North East Lincolnshire',
      'North Lincolnshire',
      'York',
      'Derby',
      'Leicester',
      'Rutland',
      'Nottingham',
      'Herefordshire, County of',
      'Telford and Wrekin',
      'Stoke-on-Trent',
      'Bath and North East Somerset',
      'Bristol, City of',
      'North Somerset',
      'South Gloucestershire',
      'Plymouth',
      'Torbay',
      'Swindon',
      'Peterborough',
      'Luton',
      'Southend-on-Sea',
      'Thurrock',
      'Medway',
      'Bracknell Forest',
      'West Berkshire',
      'Reading',
      'Slough',
      'Windsor and Maidenhead',
      'Wokingham',
      'Milton Keynes',
      'Brighton and Hove',
      'Portsmouth',
      'Southampton',
      'Isle of Wight',
      'County Durham',
      'Cheshire East',
      'Cheshire West and Chester',
      'Shropshire',
      'Cornwall',
      'Isles of Scilly',
      'Wiltshire',
      'Bedford',
      'Central Bedfordshire',
      'Northumberland',
      'Bournemouth, Christchurch and Poole',
      'Dorset',
      'Buckinghamshire',
      'Cambridge',
      'East Cambridgeshire',
      'Fenland',
      'Huntingdonshire',
      'South Cambridgeshire',
      'Allerdale',
      'Barrow-in-Furness',
      'Carlisle',
      'Copeland',
      'Eden',
      'South Lakeland',
      'Amber Valley',
      'Bolsover',
      'Chesterfield',
      'Derbyshire Dales',
      'Erewash',
      'High Peak',
      'North East Derbyshire',
      'South Derbyshire',
      'East Devon',
      'Exeter',
      'Mid Devon',
      'North Devon',
      'South Hams',
      'Teignbridge',
      'Torridge',
      'West Devon',
      'Eastbourne',
      'Hastings',
      'Lewes',
      'Rother',
      'Wealden',
      'Basildon',
      'Braintree',
      'Brentwood',
      'Castle Point',
      'Chelmsford',
      'Colchester',
      'Epping Forest',
      'Harlow',
      'Maldon',
      'Rochford',
      'Tendring',
      'Uttlesford',
      'Cheltenham',
      'Cotswold',
      'Forest of Dean',
      'Gloucester',
      'Stroud',
      'Tewkesbury',
      'Basingstoke and Deane',
      'East Hampshire',
      'Eastleigh',
      'Fareham',
      'Gosport',
      'Hart',
      'Havant',
      'New Forest',
      'Rushmoor',
      'Test Valley',
      'Winchester',
      'Broxbourne',
      'Dacorum',
      'Hertsmere',
      'North Hertfordshire',
      'Three Rivers',
      'Watford',
      'Ashford',
      'Canterbury',
      'Dartford',
      'Dover',
      'Gravesham',
      'Maidstone',
      'Sevenoaks',
      'Folkestone and Hythe',
      'Swale',
      'Thanet',
      'Tonbridge and Malling',
      'Tunbridge Wells',
      'Burnley',
      'Chorley',
      'Fylde',
      'Hyndburn',
      'Lancaster',
      'Pendle',
      'Preston',
      'Ribble Valley',
      'Rossendale',
      'South Ribble',
      'West Lancashire',
      'Wyre',
      'Blaby',
      'Charnwood',
      'Harborough',
      'Hinckley and Bosworth',
      'Melton',
      'North West Leicestershire',
      'Oadby and Wigston',
      'Boston',
      'East Lindsey',
      'Lincoln',
      'North Kesteven',
      'South Holland',
      'South Kesteven',
      'West Lindsey',
      'Breckland',
      'Broadland',
      'Great Yarmouth',
      "King's Lynn and West Norfolk",
      'North Norfolk',
      'Norwich',
      'South Norfolk',
      'Corby',
      'Daventry',
      'East Northamptonshire',
      'Kettering',
      'Northampton',
      'South Northamptonshire',
      'Wellingborough',
      'Craven',
      'Hambleton',
      'Harrogate',
      'Richmondshire',
      'Ryedale',
      'Scarborough',
      'Selby',
      'Ashfield',
      'Bassetlaw',
      'Broxtowe',
      'Gedling',
      'Mansfield',
      'Newark and Sherwood',
      'Rushcliffe',
      'Cherwell',
      'Oxford',
      'South Oxfordshire',
      'Vale of White Horse',
      'West Oxfordshire',
      'Mendip',
      'Sedgemoor',
      'South Somerset',
      'Cannock Chase',
      'East Staffordshire',
      'Lichfield',
      'Newcastle-under-Lyme',
      'South Staffordshire',
      'Stafford',
      'Staffordshire Moorlands',
      'Tamworth',
      'Babergh',
      'Ipswich',
      'Mid Suffolk',
      'Elmbridge',
      'Epsom and Ewell',
      'Guildford',
      'Mole Valley',
      'Reigate and Banstead',
      'Runnymede',
      'Spelthorne',
      'Surrey Heath',
      'Tandridge',
      'Waverley',
      'Woking',
      'North Warwickshire',
      'Nuneaton and Bedworth',
      'Rugby',
      'Stratford-on-Avon',
      'Warwick',
      'Adur',
      'Arun',
      'Chichester',
      'Crawley',
      'Horsham',
      'Mid Sussex',
      'Worthing',
      'Bromsgrove',
      'Malvern Hills',
      'Redditch',
      'Worcester',
      'Wychavon',
      'Wyre Forest',
      'St Albans',
      'Welwyn Hatfield',
      'East Hertfordshire',
      'Stevenage',
      'East Suffolk',
      'West Suffolk',
      'Somerset West and Taunton',
      'Bolton',
      'Bury',
      'Manchester',
      'Oldham',
      'Rochdale',
      'Salford',
      'Stockport',
      'Tameside',
      'Trafford',
      'Wigan',
      'Knowsley',
      'Liverpool',
      'St. Helens',
      'Sefton',
      'Wirral',
      'Barnsley',
      'Doncaster',
      'Rotherham',
      'Sheffield',
      'Newcastle upon Tyne',
      'North Tyneside',
      'South Tyneside',
      'Sunderland',
      'Birmingham',
      'Coventry',
      'Dudley',
      'Sandwell',
      'Solihull',
      'Walsall',
      'Wolverhampton',
      'Bradford',
      'Calderdale',
      'Kirklees',
      'Leeds',
      'Wakefield',
      'Gateshead',
      'City of London',
      'Barking and Dagenham',
      'Barnet',
      'Bexley',
      'Brent',
      'Bromley',
      'Camden',
      'Croydon',
      'Ealing',
      'Enfield',
      'Greenwich',
      'Hackney',
      'Hammersmith and Fulham',
      'Haringey',
      'Harrow',
      'Havering',
      'Hillingdon',
      'Hounslow',
      'Islington',
      'Kensington and Chelsea',
      'Kingston upon Thames',
      'Lambeth',
      'Lewisham',
      'Merton',
      'Newham',
      'Redbridge',
      'Richmond upon Thames',
      'Southwark',
      'Sutton',
      'Tower Hamlets',
      'Waltham Forest',
      'Wandsworth',
      'Westminster',
    ],
  },
  { code: 'Channel Islands', values: ['LAD20NM'] },
  {
    code: 'Ireland',
    values: [
      'Antrim and Newtownabbey',
      'Armagh City, Banbridge and Craigavon',
      'Belfast',
      'Causeway Coast and Glens',
      'Derry City and Strabane',
      'Fermanagh and Omagh',
      'Lisburn and Castlereagh',
      'Mid and East Antrim',
      'Mid Ulster',
      'Newry, Mourne and Down',
      'Ards and North Down',
    ],
  },
  {
    code: 'Scotland',
    values: [
      'Clackmannanshire',
      'Dumfries and Galloway',
      'East Ayrshire',
      'East Lothian',
      'East Renfrewshire',
      'Na h-Eileanan Siar',
      'Falkirk',
      'Highland',
      'Inverclyde',
      'Midlothian',
      'Moray',
      'North Ayrshire',
      'Orkney Islands',
      'Scottish Borders',
      'Shetland Islands',
      'South Ayrshire',
      'South Lanarkshire',
      'Stirling',
      'Aberdeen City',
      'Aberdeenshire',
      'Argyll and Bute',
      'City of Edinburgh',
      'Renfrewshire',
      'West Dunbartonshire',
      'West Lothian',
      'Angus',
      'Dundee City',
      'East Dunbartonshire',
      'Fife',
      'Perth and Kinross',
      'Glasgow City',
      'North Lanarkshire',
    ],
  },
  {
    code: 'Wales',
    values: [
      'Isle of Anglesey',
      'Gwynedd',
      'Conwy',
      'Denbighshire',
      'Flintshire',
      'Wrexham',
      'Ceredigion',
      'Pembrokeshire',
      'Carmarthenshire',
      'Swansea',
      'Neath Port Talbot',
      'Bridgend',
      'Vale of Glamorgan',
      'Cardiff',
      'Rhondda Cynon Taf',
      'Caerphilly',
      'Blaenau Gwent',
      'Torfaen',
      'Monmouthshire',
      'Newport',
      'Powys',
      'Merthyr Tydfil',
    ],
  },
];

export const districtsDic = Object.freeze(
  Object.entries({
    HT: 'Hartlepool',
    MB: 'Middlesbrough',
    RC: 'Redcar and Cleveland',
    SM: 'Stockton-on-Tees',
    DL: 'Darlington',
    HA: 'Halton',
    WG: 'Warrington',
    BB: 'Blackburn with Darwen',
    BP: 'Blackpool',
    KH: 'Kingston upon Hull, City of',
    EY: 'East Riding of Yorkshire',
    NC: 'North East Lincolnshire',
    NI: 'North Lincolnshire}',
    YK: 'York',
    DY: 'Derby',
    LC: 'Leicester',
    RL: 'Rutland',
    NG: 'Nottingham',
    HD: 'Herefordshire, County of',
    TF: 'Telford and Wrekin',
    SJ: 'Stoke-on-Trent',
    BS: 'Bath and North East Somerset',
    BZ: 'Bristol, City of',
    NS: 'North Somerset',
    SG: 'South Gloucestershire',
    PY: 'Plymouth',
    TB: 'Torbay',
    SD: 'Swindon',
    PE: 'Peterborough',
    LN: 'Luton',
    YS: 'Southend-on-Sea',
    TU: 'Thurrock',
    ME: 'Medway',
    BC: 'Bracknell Forest',
    WB: 'West Berkshire',
    RG: 'Reading',
    YT: 'Slough',
    WC: 'Windsor and Maidenhead',
    WJ: 'Wokingham',
    MK: 'Milton Keynes',
    BH: 'Brighton and Hove',
    PO: 'Portsmouth',
    SO: 'Southampton',
    IW: 'Isle of Wight',
    DU: 'County Durham',
    CE: 'Cheshire East',
    CW: 'Cheshire West and Chester',
    SH: 'Shropshire',
    CN: 'Cornwall',
    IS: 'Isles of Scilly',
    WT: 'Wiltshire',
    BF: 'Bedford',
    CF: 'Central Bedfordshire',
    ND: 'Northumberland',
    BU: 'Bournemouth, Christchurch and Poole',
    DT: 'Dorset',
    BM: 'Buckinghamshire',
    CB: 'Cambridge',
    EC: 'East Cambridgeshire',
    FL: 'Fenland',
    HS: 'Huntingdonshire',
    SC: 'South Cambridgeshire',
    AD: 'Allerdale',
    BIF: 'Barrow-in-Furness',
    CL: 'Carlisle',
    CLD: 'Copeland',
    ED: 'Eden',
    SL: 'South Lakeland',
    AV: 'Amber Valley',
    BO: 'Bolsover',
    CHF: 'Chesterfield',
    DD: 'Derbyshire Dales',
    EW: 'Erewash',
    HP: 'High Peak',
    HED: 'North East Derbyshire',
    SDS: 'South Derbyshire',
    ESD: 'East Devon',
    EX: 'Exeter',
    MD: 'Mid Devon',
    NRD: 'North Devon',
    SHS: 'South Hams',
    TEB: 'Teignbridge',
    TR: 'Torridge',
    WD: 'West Devon',
    EB: 'Eastbourne',
    HST: 'Hastings',
    LW: 'Lewes',
    RT: 'Rother',
    WED: 'Wealden',
    BLD: 'Basildon',
    BRT: 'Braintree',
    BTW: 'Brentwood',
    CP: 'Castle Point',
    CLF: 'Chelmsford',
    CLS: 'Colchester',
    EPF: 'Epping Forest',
    HL: 'Harlow',
    MDN: 'Maldon',
    RF: 'Rochford',
    TDR: 'Tendring',
    UF: 'Uttlesford',
    CH: 'Cheltenham',
    CTS: 'Cotswold',
    FOD: 'Forest of Dean',
    GST: 'Gloucester',
    ST: 'Stroud',
    TKB: 'Tewkesbury',
    BSD: 'Basingstoke and Deane',
    EH: 'East Hampshire',
    EL: 'Eastleigh',
    FH: 'Fareham',
    GS: 'Gosport',
    HRT: 'Hart',
    HVT: 'Havant',
    NF: 'New Forest',
    RM: 'Rushmoor',
    TV: 'Test Valley',
    WCH: 'Winchester',
    BXB: 'Broxbourne',
    DC: 'Dacorum',
    HM: 'Hertsmere',
    NHS: 'North Hertfordshire',
    THR: 'Three Rivers',
    WF: 'Watford',
    AF: 'Ashford',
    CTB: 'Canterbury',
    DF: 'Dartford',
    DV: 'Dover',
    GH: 'Gravesham',
    MS: 'Maidstone',
    SN: 'Sevenoaks',
    FSH: 'Folkestone and Hythe',
    SWL: 'Swale',
    THN: 'Thanet',
    TBM: 'Tonbridge and Malling',
    TBW: 'Tunbridge Wells',
    BL: 'Burnley',
    CHL: 'Chorley',
    FLD: 'Fylde',
    HNB: 'Hyndburn',
    LCS: 'Lancaster',
    PND: 'Pendle',
    PRS: 'Preston',
    RV: 'Ribble Valley',
    RD: 'Rossendale',
    SR: 'South Ribble',
    WL: 'West Lancashire',
    WY: 'Wyre',
    BLY: 'Blaby',
    CHW: 'Charnwood',
    HRB: 'Harborough',
    HAB: 'Hinckley and Bosworth',
    MLT: 'Melton',
    NWL: 'North West Leicestershire',
    ODW: 'Oadby and Wigston',
    BST: 'Boston',
    ESL: 'East Lindsey',
    LCN: 'Lincoln',
    NK: 'North Kesteven',
    SHL: 'South Holland',
    SKV: 'South Kesteven',
    WLS: 'West Lindsey',
    BRL: 'Breckland',
    BDL: 'Broadland',
    GY: 'Great Yarmouth',
    KLW: "King's Lynn and West Norfolk",
    NN: 'North Norfolk',
    NW: 'Norwich',
    SNK: 'South Norfolk',
    CRB: 'Corby',
    DVT: 'Daventry',
    ENH: 'East Northamptonshire',
    KET: 'Kettering',
    NHT: 'Northampton',
    SNH: 'South Northamptonshire',
    WBH: 'Wellingborough',
    CRV: 'Craven',
    HBT: 'Hambleton',
    HGT: 'Harrogate',
    RMS: 'Richmondshire',
    RYD: 'Ryedale',
    SBG: 'Scarborough',
    SLB: 'Selby',
    ASF: 'Ashfield',
    BSL: 'Bassetlaw',
    BXT: 'Broxtowe',
    GL: 'Gedling',
    MF: 'Mansfield',
    NAS: 'Newark and Sherwood',
    RSC: 'Rushcliffe',
    CRW: 'Cherwell',
    OXF: 'Oxford',
    SOX: 'South Oxfordshire',
    VWH: 'Vale of White Horse',
    WOS: 'West Oxfordshire',
    MDP: 'Mendip',
    SDM: 'Sedgemoor',
    STS: 'South Somerset',
    CNC: 'Cannock Chase',
    ESS: 'East Staffordshire',
    LCF: 'Lichfield',
    NCL: 'Newcastle-under-Lyme',
    SSS: 'South Staffordshire',
    SFF: 'Stafford',
    SFM: 'Staffordshire Moorlands',
    TW: 'Tamworth',
    BBG: 'Babergh',
    IPS: 'Ipswich',
    MSF: 'Mid Suffolk',
    ELB: 'Elmbridge',
    EPE: 'Epsom and Ewell',
    GF: 'Guildford',
    MV: 'Mole Valley',
    RGB: 'Reigate and Banstead',
    RNM: 'Runnymede',
    SPT: 'Spelthorne',
    SRH: 'Surrey Heath',
    TNR: 'Tandridge',
    WVL: 'Waverley',
    WK: 'Woking',
    NWS: 'North Warwickshire',
    NUB: 'Nuneaton and Bedworth',
    RUG: 'Rugby',
    SFA: 'Stratford-on-Avon',
    WW: 'Warwick',
    ADU: 'Adur',
    ARU: 'Arun',
    CHT: 'Chichester',
    CWL: 'Crawley',
    HRH: 'Horsham',
    MSX: 'Mid Sussex',
    WRT: 'Worthing',
    BRG: 'Bromsgrove',
    MLH: 'Malvern Hills',
    RDD: 'Redditch',
    WRC: 'Worcester',
    WYC: 'Wychavon',
    WRF: 'Wyre Forest',
    STA: 'St Albans',
    WLH: 'Welwyn Hatfield',
    EHS: 'East Hertfordshire',
    STV: 'Stevenage',
    ES: 'East Suffolk',
    WS: 'West Suffolk',
    SWT: 'Somerset West and Taunton',
    BLT: 'Bolton',
    BRY: 'Bury',
    MCS: 'Manchester',
    ODH: 'Oldham',
    RCD: 'Rochdale',
    SLF: 'Salford',
    STP: 'Stockport',
    TSD: 'Tameside',
    TRF: 'Trafford',
    WIG: 'Wigan',
    KNW: 'Knowsley',
    LVP: 'Liverpool',
    STH: 'St. Helens',
    SFT: 'Sefton',
    WIR: 'Wirral',
    BRS: 'Barnsley',
    DNC: 'Doncaster',
    RTH: 'Rotherham',
    SHF: 'Sheffield',
    NCT: 'Newcastle upon Tyne',
    NTS: 'North Tyneside',
    SOS: 'South Tyneside',
    SDL: 'Sunderland',
    BRM: 'Birmingham',
    CVT: 'Coventry',
    DDL: 'Dudley',
    SNW: 'Sandwell',
    SLH: 'Solihull',
    WSL: 'Walsall',
    WLV: 'Wolverhampton',
    BRF: 'Bradford',
    CDL: 'Calderdale',
    KRK: 'Kirklees',
    LEE: 'Leeds',
    WKF: 'Wakefield',
    GHE: 'Gateshead',
    LON: 'City of London',
    BDG: 'Barking and Dagenham',
    BRN: 'Barnet',
    BXL: 'Bexley',
    BRE: 'Brent',
    BML: 'Bromley',
    CMD: 'Camden',
    CRY: 'Croydon',
    EAL: 'Ealing',
    ENF: 'Enfield',
    GRW: 'Greenwich',
    HCK: 'Hackney',
    HSF: 'Hammersmith and Fulham',
    HRG: 'Haringey',
    HRW: 'Harrow',
    HVR: 'Havering',
    HLD: 'Hillingdon',
    HLW: 'Hounslow',
    ISL: 'Islington',
    KNC: 'Kensington and Chelsea',
    KIT: 'Kingston upon Thames',
    LAM: 'Lambeth',
    LWH: 'Lewisham',
    MRT: 'Merton',
    NWH: 'Newham',
    RDB: 'Redbridge',
    RCT: 'Richmond upon Thames',
    STW: 'Southwark',
    SUT: 'Sutton',
    TOH: 'Tower Hamlets',
    WLF: 'Waltham Forest',
    WDW: 'Wandsworth',
    WSM: 'Westminster',
    L20: 'LAD20NM',
    ANN: 'Antrim and Newtownabbey',
    ABC: 'Armagh City, Banbridge and Craigavon',
    BLF: 'Belfast',
    CCG: 'Causeway Coast and Glens',
    DCS: 'Derry City and Strabane',
    FOM: 'Fermanagh and Omagh',
    LSC: 'Lisburn and Castlereagh',
    MEA: 'Mid and East Antrim',
    MUL: 'Mid Ulster',
    NMD: 'Newry, Mourne and Down',
    AND: 'Ards and North Down',
    CLM: 'Clackmannanshire',
    DGW: 'Dumfries and Galloway',
    EAY: 'East Ayrshire',
    ELT: 'East Lothian',
    ERF: 'East Renfrewshire',
    NES: 'Na h-Eileanan Siar',
    FLK: 'Falkirk',
    HGL: 'Highland',
    INC: 'Inverclyde',
    MDL: 'Midlothian',
    MOR: 'Moray',
    NRH: 'North Ayrshire',
    ORI: 'Orkney Islands',
    SCB: 'Scottish Borders',
    SHI: 'Shetland Islands',
    SOA: 'South Ayrshire',
    SLK: 'South Lanarkshire',
    STL: 'Stirling',
    ADC: 'Aberdeen City',
    ADS: 'Aberdeenshire',
    AGB: 'Argyll and Bute',
    EDI: 'City of Edinburgh',
    RFR: 'Renfrewshire',
    WDT: 'West Dunbartonshire',
    WLT: 'West Lothian',
    ANG: 'Angus',
    DUN: 'Dundee City',
    EDT: 'East Dunbartonshire',
    FIF: 'Fife',
    PKI: 'Perth and Kinross',
    GLA: 'Glasgow City',
    NLK: 'North Lanarkshire',
    IAN: 'Isle of Anglesey',
    GWN: 'Gwynedd',
    CON: 'Conwy',
    DBS: 'Denbighshire',
    FLS: 'Flintshire',
    WX: 'Wrexham',
    CER: 'Ceredigion',
    PEM: 'Pembrokeshire',
    CMT: 'Carmarthenshire',
    SWS: 'Swansea',
    NPT: 'Neath Port Talbot',
    BRD: 'Bridgend',
    VLG: 'Vale of Glamorgan',
    CAR: 'Cardiff',
    RHC: 'Rhondda Cynon Taf',
    CAE: 'Caerphilly',
    BLG: 'Blaenau Gwent',
    TOR: 'Torfaen',
    MON: 'Monmouthshire',
    NWP: 'Newport',
    PWY: 'Powys',
    MET: 'Merthyr Tydfil',
  }).reduce((acc, [key, value]): any => {
    return [...acc, { label: value, value: key }];
  }, [])
);

export const nationalityList = [
  'Afghan',
  'Albanian',
  'Algerian',
  'American',
  'Andorran',
  'Angolan',
  'Antiguans',
  'Argentinean',
  'Armenian',
  'Australian',
  'Austrian',
  'Azerbaijani',
  'Bahamian',
  'Bahraini',
  'Bangladeshi',
  'Barbadian',
  'Barbudans',
  'Batswana',
  'Belarusian',
  'Belgian',
  'Belizean',
  'Beninese',
  'Bhutanese',
  'Bolivian',
  'Bosnian',
  'Brazilian',
  'British',
  'Bruneian',
  'Bulgarian',
  'Burkinabe',
  'Burmese',
  'Burundian',
  'Cambodian',
  'Cameroonian',
  'Canadian',
  'Cape Verdean',
  'Central African',
  'Chadian',
  'Chilean',
  'Chinese',
  'Colombian',
  'Comoran',
  'Congolese',
  'Costa Rican',
  'Croatian',
  'Cuban',
  'Cypriot',
  'Czech',
  'Danish',
  'Djibouti',
  'Dominican',
  'Dutch',
  'East Timorese',
  'Ecuadorean',
  'Egyptian',
  'Emirian',
  'Equatorial Guinean',
  'Eritrean',
  'Estonian',
  'Ethiopian',
  'Fijian',
  'Filipino',
  'Finnish',
  'French',
  'Gabonese',
  'Gambian',
  'Georgian',
  'German',
  'Ghanaian',
  'Greek',
  'Grenadian',
  'Guatemalan',
  'Guinea-Bissauan',
  'Guinean',
  'Guyanese',
  'Haitian',
  'Herzegovinian',
  'Honduran',
  'Hungarian',
  'I-Kiribati',
  'Icelander',
  'Indian',
  'Indonesian',
  'Iranian',
  'Iraqi',
  'Irish',
  'Israeli',
  'Italian',
  'Ivorian',
  'Jamaican',
  'Japanese',
  'Jordanian',
  'Kazakhstani',
  'Kenyan',
  'Kittian and Nevisian',
  'Kuwaiti',
  'Kyrgyz',
  'Laotian',
  'Latvian',
  'Lebanese',
  'Liberian',
  'Libyan',
  'Liechtensteiner',
  'Lithuanian',
  'Luxembourger',
  'Macedonian',
  'Malagasy',
  'Malawian',
  'Malaysian',
  'Maldivan',
  'Malian',
  'Maltese',
  'Marshallese',
  'Mauritanian',
  'Mauritian',
  'Mexican',
  'Micronesian',
  'Moldovan',
  'Monacan',
  'Mongolian',
  'Moroccan',
  'Mosotho',
  'Motswana',
  'Mozambican',
  'Namibian',
  'Nauruan',
  'Nepalese',
  'New Zealander',
  'Nicaraguan',
  'Nigerian',
  'Nigerien',
  'North Korean',
  'Northern Irish',
  'Norwegian',
  'Omani',
  'Pakistani',
  'Palauan',
  'Panamanian',
  'Papua New Guinean',
  'Paraguayan',
  'Peruvian',
  'Polish',
  'Portuguese',
  'Qatari',
  'Romanian',
  'Russian',
  'Rwandan',
  'Saint Lucian',
  'Salvadoran',
  'Samoan',
  'San Marinese',
  'Sao Tomean',
  'Saudi',
  'Scottish',
  'Senegalese',
  'Serbian',
  'Seychellois',
  'Sierra Leonean',
  'Singaporean',
  'Slovakian',
  'Slovenian',
  'Solomon Islander',
  'Somali',
  'South African',
  'South Korean',
  'Spanish',
  'Sri Lankan',
  'Sudanese',
  'Surinamer',
  'Swazi',
  'Swedish',
  'Swiss',
  'Syrian',
  'Taiwanese',
  'Tajik',
  'Tanzanian',
  'Thai',
  'Togolese',
  'Tongan',
  'Trinidadian or Tobagonian',
  'Tunisian',
  'Turkish',
  'Tuvaluan',
  'Ugandan',
  'Ukrainian',
  'Uruguayan',
  'Uzbekistani',
  'Venezuelan',
  'Vietnamese',
  'Welsh',
  'Yemenite',
  'Zambian',
  'Zimbabwean',
];

export const nationalityDic = Object.freeze(
  nationalityList.reduce((acc, value): any => {
    return [...acc, { label: value, key: value.trim() }];
  }, [])
);

export const userStateDic = Object.freeze({
  ACTIVATED: 'VERIFIED',
  DEACTIVATED: 'DEACTIVATED',
});

const banksLogosUrl = `${process.env.SWOOP_CONTENT_HOST}/images/banks-logos`;

export const mapFunderNameToBankLogo: { [key: string]: any } = Object.freeze({
  ADAM_AND_COMPANY: `${banksLogosUrl}/adamAndCompanyBank.png`,
  AIB__GB: `${banksLogosUrl}/alliedIrishBank.png`,
  'Bank of Ireland': `${banksLogosUrl}/bankOfIreland.png`,
  BANK_OF_IRELAND__GB: `${banksLogosUrl}/bankOfIreland.png`,
  'Bank of Scotland': `${banksLogosUrl}/bankOfScotland.png`,
  BANK_OF_SCOTLAND: `${banksLogosUrl}/bankOfScotland.png`,
  Barclays: `${banksLogosUrl}/barclaysBank.svg`,
  BARCLAYS: `${banksLogosUrl}/barclaysBank.svg`,
  Danske: `${banksLogosUrl}/danskeBank.svg`,
  DANSKE_BANK__NI: `${banksLogosUrl}/danskeBank.svg`,
  COUTTS: `${banksLogosUrl}/couttsBank.png`,
  HSBC: `${banksLogosUrl}/hsbc-uk.svg`,
  'Lloyds Bank': `${banksLogosUrl}/lloydsBank.svg`,
  LLOYDS_BANK: `${banksLogosUrl}/lloydsBank.svg`,
  'Metro Bank': `${banksLogosUrl}/Metro.svg`,
  METRO_BANK: `${banksLogosUrl}/Metro.svg`,
  NATWEST: `${banksLogosUrl}/natWestBank.jpg`,
  RBS: `${banksLogosUrl}/royalBankOfScotland.svg`,
  Santander: `${banksLogosUrl}/santanderBank.svg`,
  STARLING_BANK: `${banksLogosUrl}/starling.svg`,
  SANTANDER: `${banksLogosUrl}/santanderBank.svg`,
  THE_CO_OPERATIVE_BANK: `${banksLogosUrl}/co-op.svg`,
  'Ulster Bank (IRE)': `${banksLogosUrl}/ulsterBank.png`,
  ULSTER_BANK__GB: `${banksLogosUrl}/ulsterBank.png`,
});

export const accountSourceDic = Object.freeze([
  { label: 'Social media', value: 'SOCIAL_MEDIA' },
  { label: 'Google', value: 'GOOGLE' },
  { label: 'Press', value: 'PRESS' },
  { label: 'Friend/Family', value: 'FRIEND_FAMILY' },
  { label: 'My Accountant/Business Advisor', value: 'ADVISOR' },
  { label: 'Media', value: 'MEDIA' },
  { label: 'Event', value: 'EVENT' },
]);

export const fundingNeedsDic = Object.freeze([
  { label: 'Loans', value: 'LOANS' },
  { label: 'Equity', value: 'EQUITY' },
  { label: 'Grants', value: 'GRANTS' },
]);

export const activitiesToFund = Object.freeze([
  { label: 'Covid-19 Impact', value: 'Covid-19 Impact' },
  { label: 'Business expansion', value: 'Business expansion' },
  { label: 'Start a business', value: 'Start a business' },
  { label: 'Acquire a business', value: 'Acquire a business' },
  { label: 'Research', value: 'Research' },
  { label: 'Product development', value: 'Product development' },
  { label: 'Import/Export finance', value: 'Import/Export finance' },
  { label: 'Purchase stock', value: 'Purchase stock' },
  { label: 'Improve cashflow', value: 'Improve cashflow' },
  { label: 'Purchase or develop a property', value: 'Purchase or develop a property' },
  { label: 'Debt refinance ', value: 'Debt refinance ' },
  { label: 'Purchase or refinance of an asset', value: 'Purchase or refinance of an asset' },
]);

export const availableActivitesList = activitiesToFund.map(({ value }: { value: string }) => value);

export const financialsDocumentsDic: Array<{ label: string; value: string }> = [
  { label: '1 Year Filed Accounts', value: '1 Year Filed Accounts' },
  { label: '2 Years Filed Accounts', value: '2 Years Filed Accounts' },
  { label: 'Draft Accounts for Previous Year', value: 'Draft Accounts for Previous Year' },
  { label: 'Management Accounts', value: 'Management Accounts' },
  { label: '3 Months Company Bank Statements', value: '3 Months Company Bank Statements' },
  { label: '6 Months Company Bank Statements', value: '6 Months Company Bank Statements' },
  { label: '12 Months Company Bank Statements', value: '12 Months Company Bank Statements' },
  {
    label: '12 Months Merchant Terminal Statements',
    value: '12 Months Merchant Terminal Statements',
  },
  { label: i18n.t('general:vatreturns'), value: i18n.t('general:vatreturns') },
  { label: 'Summary Aged Debtors Report', value: 'Summary Aged Debtors Report' },
  { label: 'Detailed Aged Debtors Report', value: 'Detailed Aged Debtors Report' },
  { label: 'Summary Aged Creditors Report', value: 'Summary Aged Creditors Report' },
  { label: 'Detailed Aged Creditors Report', value: 'Detailed Aged Creditors Report' },
  {
    label: i18n.t('home:products:fields:fixedAssetSchedule:label'),
    value: i18n.t('home:products:fields:fixedAssetSchedule:label'),
  },
  { label: 'A Schedule of Outstanding Debt', value: 'A Schedule of Outstanding Debt' },
  {
    label: 'Statement of Assets & Liabilities & Income & Expenditure',
    value: 'Statement of Assets & Liabilities & Income & Expenditure',
  },
  { label: 'Cashflow Forecast', value: 'Cashflow Forecast' },
  { label: 'Capital Report', value: 'Capital Report' },
  { label: 'Credit Report', value: 'Credit Report' },
];

export const ownershipDocumentsDic: Array<{ label: string; value: string }> = [
  {
    label: i18n.t('home:products:fields:certifiedCopyOfIncorporation:label'),
    value: i18n.t('home:products:fields:certifiedCopyOfIncorporation:label'),
  },
];

export const applicationDocumentsDic: Array<{ label: string; value: string }> = [
  {
    label: 'Completed Application Form',
    value: 'Completed Application Form',
  },
  {
    label: 'Personal Guarantee',
    value: 'Personal Guarantee',
  },
];

export const fundingDocumentsDic: Array<{ label: string; value: string }> = [
  { label: 'A Business Plan', value: 'A Business Plan' },
  { label: 'Pitch Deck', value: 'Pitch Deck' },
  { label: 'A Financial Model', value: 'A Financial Model' },
  { label: 'Financial Forecast', value: 'Financial Forecast' },
];

export const identificationDocumentsDic: Array<{ label: string; value: string }> = [
  { label: 'Proof of Identity', value: 'Proof of Identity' },
  {
    label: 'Proof of Address (Dated Within Last 3 Months)',
    value: 'Proof of Address (Dated Within Last 3 Months)',
  },
  { label: 'Asset & Liability Statements', value: 'Asset & Liability Statements' },
];

export const otherDocumentDic: Array<{ label: string; value: string }> = [
  {
    label: '',
    value: 'Custom',
  },
];

export const docTypes: { [key: string]: any } = {
  Financials: financialsDocumentsDic,
  Ownership: ownershipDocumentsDic,
  Applications: applicationDocumentsDic,
  FundingDocuments: fundingDocumentsDic,
  Identification: identificationDocumentsDic,
  Other: otherDocumentDic,
};

export const docTypeCategoryOptions = Object.keys(docTypes).map((docType: string) => {
  return {
    label: docType,
    value: docType,
    name: docType,
  };
});

export const fundingRequiredTimescale = [
  { label: 'Up to 1 week', value: 'UpToOneWeek' },
  { label: '1-2 weeks', value: 'OneToTwoWeeks' },
  { label: '2-4 weeks', value: 'TwoToFourWeeks' },
  { label: '1-2 months', value: 'OneToTwoMonths' },
  { label: '2 months +', value: 'TwoMonthsPlus' },
];

export const clientManagementType = [
  { value: '0', label: 'Select the type of management' },
  { value: '1', label: 'Managed by Swoop' },
  { value: '2', label: 'Managed by you' },
];

/**
 * Legacy options which now map to new goals on marketplace by formId.
 */
export const fundingPurposes = [
  {
    label: 'Covid-19 Impact',
    value: 1,
    formId: '494ECBC8-5815-4125-A4F0-60FB7A7215C3',
  },
  {
    label: 'Start a business',
    value: 2,
    formId: 'A9C81745-3411-495C-BA93-DBC6C0C863C4',
  },
  {
    label: 'Research',
    value: 3,
    formId: '9E453023-08D4-4AC3-84B5-44029B167D67',
  },
  {
    label: 'Import/Export finance',
    value: 4,
    formId: 'E44E8DAE-61B3-49C0-BD8B-2750269FEA9E',
  },
  {
    label: 'Improve cashflow',
    value: 5,
    formId: '494ECBC8-5815-4125-A4F0-60FB7A7215C3',
  },
  {
    label: 'Debt refinance',
    value: 6,
    formId: 'E90A6827-AAB3-4A57-B11C-B95C2C4EBC1B',
  },
  {
    label: 'Business expansion',
    value: 7,
    formId: '2DACDE3A-547D-4E07-A969-B587C693C5F1',
  },
  {
    label: 'Acquire a business',
    value: 8,
    formId: '28FD47D5-1DF3-4693-A563-07815B0C5E80',
  },
  {
    label: 'Product development',
    value: 9,
    formId: '9E453023-08D4-4AC3-84B5-44029B167D67',
  },
  {
    label: 'Purchase stock',
    value: 10,
  },
  {
    label: 'Purchase or develop a property',
    value: 11,
  },
  {
    label: 'Purchase or refinance of an asset',
    value: 12,
  },
];

export const monthsDic = [
  { label: 'January', value: 'January' },
  { label: 'February', value: 'February' },
  { label: 'March', value: 'March' },
  { label: 'April', value: 'April' },
  { label: 'May', value: 'May' },
  { label: 'June', value: 'June' },
  { label: 'July', value: 'July' },
  { label: 'August', value: 'August' },
  { label: 'September', value: 'September' },
  { label: 'October', value: 'October' },
  { label: 'November', value: 'November' },
  { label: 'December', value: 'December' },
];

export const fundingRequiredTimescaleMetadata = Object.freeze([
  { value: 1, label: 'Up to 1 week' },
  { value: 2, label: '1-2 weeks' },
  { value: 3, label: '2-4 weeks' },
  { value: 4, label: '1-2 months' },
  { value: 5, label: '2 months +' },
]);

export const residentialStatus = [
  { label: 'None', value: '' },
  { label: 'Home Owner (With Mortgage)', value: 'HomeOwner' },
  { label: 'Home Owner (No Mortgage)', value: 'HomeOwnerNoMortgage' },
  { label: 'Living With Parents', value: 'LivingWithParents' },
  { label: 'Tenant', value: 'Tenant' },
  { label: 'Private Tenant', value: 'Tenant' },
  { label: 'Council Tenant', value: 'Tenant' },
];

export const creditPassportValues = [
  {
    label: 'A++',
    gradeAsPerCreditPassport: 'Excellent',
    yourGradeExplainer: 'It looks like the business would make an excellent lending prospect.',
    whatThisMeans:
      'With a low probability of default and an excellent credit profile, the business is likely to qualify for more products, and the cost of borrowing should be lower.',
    behaviouralAssessment:
      'Based on the current account activity, it looks like the business would make an excellent lending prospect.',
    financialAssessment:
      'Based on the filed accounts, the business is in an excellent position in terms of cash and assets.',
  },
  {
    label: 'A+',
    gradeAsPerCreditPassport: 'High',
    yourGradeExplainer: 'It looks like the business would make a great lending prospect.',
    whatThisMeans:
      'With a low probability of default and a great credit profile, the business is likely to qualify for more products, and the cost of borrowing should be lower.',
    behaviouralAssessment:
      'Based on the current account activity, it looks like the business would make a great lending prospect.',
    financialAssessment:
      'Based on the filed accounts, the business is in a great position in terms of cash and assets.',
  },
  {
    label: 'A',
    gradeAsPerCreditPassport: 'Good',
    yourGradeExplainer: 'It looks like the business would make a good lending prospect.',
    whatThisMeans:
      'With a low probability of default and a good credit profile, the business is likely to qualify for more products, and the cost of borrowing should be lower.',
    behaviouralAssessment:
      'Based on the current account activity, it looks like the business would make a good lending prospect.',
    financialAssessment:
      'Based on the filed accounts, the business is in a good position in terms of cash and assets.',
  },
  {
    label: 'B',
    gradeAsPerCreditPassport: 'Sound',
    yourGradeExplainer: 'It looks like the business is a typical lending prospect.',
    whatThisMeans:
      'With an average probability of default and credit profile, the business is likely to qualify for loans, at typical rates.',
    behaviouralAssessment:
      'Based on the current account activity, it looks like the business is a typical lending prospect.',
    financialAssessment:
      'Based on the filed accounts, the business is in an ok position in terms of cash and assets.',
  },
  {
    label: 'C',
    gradeAsPerCreditPassport: 'Moderate',
    yourGradeExplainer: 'It looks like the business is a typical lending prospect.',
    whatThisMeans:
      'With an average probability of default and credit profile, the business is likely to qualify for loans, at typical rates.',
    behaviouralAssessment:
      'Based on the current account activity, it looks like the business is a typical lending prospect.',
    financialAssessment:
      'Based on the filed accounts, the business is in an ok position in terms of cash and assets.',
  },
  {
    label: 'D',
    gradeAsPerCreditPassport: 'Low',
    yourGradeExplainer: 'It looks like the business would make a risky lending prospect.',
    whatThisMeans:
      'With a high probability of default and a poor credit profile, the cost of borrowing may be high. The options available to the business will be more restricted, but there are specialist providers available.',
    behaviouralAssessment:
      'Based on the current account activity, it looks like the business would make a risky lending prospect.',
    financialAssessment:
      'Based on the filed accounts, the business is in a poor position in terms of cash and assets.',
  },
  {
    label: 'E',
    gradeAsPerCreditPassport: 'Critical',
    yourGradeExplainer: 'It looks like the business would make a very risky lending prospect.',
    whatThisMeans:
      'With a high probability of default and a critical credit profile, the cost of borrowing may be high. The options available to the business will be more restricted, but there are specialist providers available.',
    behaviouralAssessment:
      'Based on the current account activity, it looks like the business would make a very risky lending prospect.',
    financialAssessment:
      'Based on the filed accounts, the business is in a critical position in terms of cash and assets.',
  },
];

export const dealStatusLabels = {
  [DealStatus.Created]: 'Created',
  [DealStatus.InformationRequested]: 'Information Requested',
  [DealStatus.AwaitingDocuments]: 'Awaiting Documents',
  [DealStatus.SwoopReview]: 'Swoop Review',
  [DealStatus.Qualified]: 'Qualified',
  [DealStatus.Offer]: 'Offer',
  [DealStatus.Underwriting]: 'Underwriting',
  [DealStatus.ClosedWon]: 'Closed Won',
  [DealStatus.ClosedLost]: 'Closed Lost',
  [DealStatus.Funded]: 'Funded',
  [DealStatus.OfferAccepted]: 'Offer Accepted',
  [DealStatus.Matched]: 'Matched',
};

export const dealsStatusList: { [key: string]: { label: string; value: string } } = {
  Created: {
    value: '0',
    label: 'Created',
  },
  InformationRequested: {
    value: '1',
    label: 'Information Requested',
  },
  AwaitingDocuments: {
    value: '2',
    label: 'Awaiting Documents',
  },
  SwoopReview: {
    value: '3',
    label: 'Swoop Review',
  },
  Qualified: {
    value: '4',
    label: 'Qualified',
  },
  Matched: {
    value: '9',
    label: 'Matched',
  },
  Underwriting: {
    value: '6',
    label: 'Underwriting',
  },
  Offer: {
    value: '5',
    label: 'Offer',
  },
  OfferAccepted: {
    value: '10',
    label: 'Offer Accepted',
  },
  Funded: {
    value: '11',
    label: 'Funded',
  },
  ClosedWon: {
    value: '7',
    label: 'Closed Won',
  },
  ClosedLost: {
    value: '8',
    label: 'Closed Lost',
  },
};

export const dealStatusListOptions: TOption[] = Object.keys(dealsStatusList).map((key) => {
  return {
    id: dealsStatusList[key].label,
    label: dealsStatusList[key].label,
    value: dealsStatusList[key].value,
  };
});

export const pageSizes = [
  {
    id: '20',
    label: '20 per page',
    value: '20',
  },
  {
    id: '50',
    label: '50 per page',
    value: '50',
  },
  {
    id: '100',
    label: '100 per page',
    value: '100',
  },
];

export const datePickerRange = {
  startYear: {
    value: 1900,
  },
  endYear: {
    value: 2100,
  },
};

export const productLoanFilterSubtypeDic: Array<{ label: string; value: string }> = [
  { label: 'Asset Finance and Leasing', value: 'AssetFinanceLeasing' },
  { label: 'Commercial Mortgage', value: 'CommercialMortgage' },
  { label: 'Credit Cards', value: 'CreditCards' },
  { label: 'Crowd Funding Loan', value: 'CrowdfundingLoan' },
  { label: 'Invoice Finance', value: 'InvoiceFinance' },
  { label: 'MBO Finance', value: 'MboFinance' },
  { label: 'Merchant Finance', value: 'MerchantFinance' },
  { label: 'Order Finance', value: 'OrderFinance' },
  { label: 'Overdrafts', value: 'Overdrafts' },
  { label: 'R and D Tax Loan', value: 'RAndDTaxLoan' },
  { label: 'Start up Loan', value: 'StartupLoan' },
  { label: 'Supplier Finance', value: 'SupplierFinance' },
  { label: 'Term Loan', value: 'TermLoan' },
  { label: 'Working Capital Loan', value: 'WorkingCapitalLoan' },
];

export const productEquityFilterSubtypeDic: Array<{ label: string; value: string }> = [
  { label: 'Angel', value: 'Angel' },
  { label: 'Crowd Funding Equity', value: 'CrowdfundingEquity' },
  { label: 'EIS', value: 'EIS' },
  { label: 'Family Office', value: 'FamilyOffice' },
  { label: 'Private Equity', value: 'PrivateEquity' },
  { label: 'SEIS', value: 'SEIS' },
  { label: 'VCT', value: 'VCT' },
  { label: 'VC', value: 'VC' },
  { label: 'Pitch Review', value: 'PitchReview' },
  { label: 'Swoop Services', value: 'SwoopServices' },
];

export const productGrantFilterSubtypeDic: Array<{ label: string; value: string }> = [];

export const productFilterTypes: { [key: string]: any } = {
  Loan: productLoanFilterSubtypeDic,
  Equity: productEquityFilterSubtypeDic,
  Grant: productGrantFilterSubtypeDic,
};

export const productFilterTypeCategoryOptions = Object.keys(productFilterTypes).map(
  (productFilterType: string) => {
    return {
      label: productFilterType,
      value: productFilterType,
      name: productFilterType,
    };
  }
);

export const fundingRequiredInsightId = '83d34923-7bda-4c78-9784-fe072432cb60';
export const decisionTimeInsightId = '9659b104-d0f9-41a7-a224-b78c125adf8c';

export const shortMonthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const monthFilterOptions = [
  {
    label: 'Month 1',
    value: 1,
  },
  {
    label: 'Month 2',
    value: 2,
  },
  {
    label: 'Month 3',
    value: 3,
  },
  {
    label: 'Month 4',
    value: 4,
  },
  {
    label: 'Month 5',
    value: 5,
  },
  {
    label: 'Month 6',
    value: 6,
  },
  {
    label: 'Month 7',
    value: 7,
  },
  {
    label: 'Month 8',
    value: 8,
  },
  {
    label: 'Month 9',
    value: 9,
  },
  {
    label: 'Month 10',
    value: 10,
  },
  {
    label: 'Month 11',
    value: 11,
  },
  {
    label: 'Month 12',
    value: 12,
  },
];

export const companyAccountStatusOptions = [
  {
    name: 'registered',
    value: 'registered',
    label: 'Registered',
  },
  {
    name: 'chaseInfo',
    value: 'chaseInfo',
    label: 'Chase info',
  },
  {
    name: 'callList',
    value: 'callList',
    label: 'Call list',
  },
  {
    name: 'marketing',
    value: 'marketing',
    label: 'Marketing',
  },
  {
    name: 'doNotContact',
    value: 'doNotContact',
    label: 'Do not contact',
  },
  {
    name: 'applicationOpen',
    value: 'applicationOpen',
    label: 'Application open',
  },
];

export const closedLostReasons = [
  { value: 'Funded - Alternative provider', label: 'Funded - Alternative provider' },
  { value: 'Funded - Alternative funding type', label: 'Funded - Alternative funding type' },
  { value: 'Broker - Rejected', label: 'Broker - Rejected' },
  { value: 'Broker - Unqualified lead', label: 'Broker - Unqualified lead' },
  { value: 'Broker - Pre-MVP', label: 'Broker - Pre-MVP' },
  { value: 'Broker - Not enough revenue', label: 'Broker - Not enough revenue' },
  {
    value: 'Broker - Lack of funding network supply',
    label: 'Broker - Lack of funding network supply',
  },
  { value: 'Lender - Credit score issue', label: 'Lender - Credit score issue' },
  {
    value: 'Lender - Revenue, debt coverage or loan balances',
    label: 'Lender - Revenue, debt coverage or loan balances',
  },
  { value: 'Lender - Time in business', label: 'Lender - Time in business' },
  { value: 'Lender - Fraud or AML Risk', label: 'Lender - Fraud or AML Risk' },
  { value: 'Lender - Too many bounced payments', label: 'Lender - Too many bounced payments' },
  {
    value: 'Lender - Average cash balance / insufficient deposits',
    label: 'Lender - Average cash balance / insufficient deposits',
  },
  { value: 'Lender - Industry type', label: 'Lender - Industry type' },
  { value: 'Client - Unresponsive', label: 'Client - Unresponsive' },
  { value: 'Client - Offer too expensive', label: 'Client - Offer too expensive' },
  { value: 'Client - No longer needed funding', label: 'Client - No longer needed funding' },
  { value: 'Client - Lost to other broker', label: 'Client - Lost to other broker' },
  { value: 'Client - Got offer by themselves', label: 'Client - Got offer by themselves' },
  {
    value: 'Client - Issue with amount or structure',
    label: 'Client - Issue with amount or structure',
  },
  {
    value: 'Client - Not wanting to pay success fee',
    label: 'Client - Not wanting to pay success fee',
  },
  { value: 'Client - Lack of Director Guarantee', label: 'Client - Lack of Director Guarantee' },
  { value: 'Vendor - Retracted Offer', label: 'Vendor - Retracted Offer' },
  { value: 'Other', label: 'Other' },
];
