import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ButtonStyleTypeEnum } from 'components/button/type';
import { FormFieldPropType } from '_shared/fieldValidation/types';
import { TOAST_ERROR_MESSAGE, TOAST_MESSAGE } from 'store/toast/types';
import validation from '_shared/fieldValidation';
import useForm from '_shared/hooks/useForm';
import styles from 'pages/companyOverview/assets/contactModal.module.scss';
import Button from 'components/button';
import Field from 'components/field';
import Modal from 'components/modal';
import { newContactFields } from 'pages/companyOverview/fields/contactInfo.fields';
import { ContactModalEnum, TContactModalProps } from './type';
import {
  deleteCompanyContact,
  postCompanyContact,
  putCompanyContact,
} from '_shared/api/businesses';
import { useAppState } from 'store';

const ContactModal = ({
  contactModalContext,
  setContactModalContext,
  selectedContact,
  setSelectedContact,
  companyId,
  fetchContactsInfo,
}: TContactModalProps) => {
  const store = useAppState();
  const { t } = useTranslation();
  const [contactModalFields, setContactModalFields] = useState<FormFieldPropType[]>([]);

  const modalsList = {
    'add-contact': {
      title: t('home:companydetails:overview:accountinfo:contact:contactmodal:addcontact:title'),
      description: t(
        'home:companydetails:overview:accountinfo:contact:contactmodal:addcontact:description'
      ),
      headerLogo: 'person_add',
      buttonText: t(
        'home:companydetails:overview:accountinfo:contact:contactmodal:addcontact:buttonText'
      ),
    },
    'edit-contact': {
      title: t('home:companydetails:overview:accountinfo:contact:contactmodal:editcontact:title'),
      description: t(
        'home:companydetails:overview:accountinfo:contact:contactmodal:editcontact:description'
      ),
      headerLogo: 'edit_note',
      buttonText: t(
        'home:companydetails:overview:accountinfo:contact:contactmodal:editcontact:buttonText'
      ),
    },
    'delete-contact': {
      title: t('home:companydetails:overview:accountinfo:contact:contactmodal:deletecontact:title'),
      description: t(
        'home:companydetails:overview:accountinfo:contact:contactmodal:deletecontact:description'
      ),
      buttonText: t('common:delete'),
    },
  };

  const clearContextAndSelectedContact = () => {
    setContactModalContext(null);
    setSelectedContact(null);
  };

  const saveOrDeleteContact = () => {
    if (contactModalContext === ContactModalEnum.ADD) return addContact();
    if (contactModalContext === ContactModalEnum.EDIT) return updateContact();
    if (contactModalContext === ContactModalEnum.DELETE) return deleteContact();
  };

  const { handleChange, handleSubmit, errors, metadata, values } = useForm(
    {},
    contactModalFields,
    saveOrDeleteContact,
    validation
  );

  const addContact = async () => {
    try {
      await postCompanyContact(companyId, { ...values });

      store.dispatch({
        type: TOAST_MESSAGE,
        payload: {
          toastMessage: t(
            'home:companydetails:overview:accountinfo:contact:contactmodal:addcontact:toastsuccess'
          ),
        },
      });
    } catch (error) {
      console.error(error);
      store.dispatch({
        type: TOAST_ERROR_MESSAGE,
        payload: {
          toastMessage: t(
            'home:companydetails:overview:accountinfo:contact:contactmodal:addcontact:toastfailed'
          ),
        },
      });
    } finally {
      clearContextAndSelectedContact();
      fetchContactsInfo(companyId);
    }
  };

  const updateContact = async () => {
    try {
      selectedContact &&
        (await putCompanyContact(companyId, selectedContact.id, { ...selectedContact, ...values }));

      store.dispatch({
        type: TOAST_MESSAGE,
        payload: {
          toastMessage: t(
            'home:companydetails:overview:accountinfo:contact:contactmodal:editcontact:toastsuccess'
          ),
        },
      });
    } catch (error) {
      console.error(error);
      store.dispatch({
        type: TOAST_ERROR_MESSAGE,
        payload: {
          toastMessage: t(
            'home:companydetails:overview:accountinfo:contact:contactmodal:editcontact:toastfailed'
          ),
        },
      });
    } finally {
      clearContextAndSelectedContact();
      fetchContactsInfo(companyId);
    }
  };

  const deleteContact = async () => {
    try {
      selectedContact && (await deleteCompanyContact(companyId, selectedContact.id));

      store.dispatch({
        type: TOAST_MESSAGE,
        payload: {
          toastMessage: t(
            'home:companydetails:overview:accountinfo:contact:contactmodal:deletecontact:toastsuccess'
          ),
        },
      });
    } catch (error) {
      console.error(error);
      store.dispatch({
        type: TOAST_ERROR_MESSAGE,
        payload: {
          toastMessage: t(
            'home:companydetails:overview:accountinfo:contact:contactmodal:deletecontact:toastfailed'
          ),
        },
      });
    } finally {
      clearContextAndSelectedContact();
      fetchContactsInfo(companyId);
    }
  };

  useEffect(() => {
    const contactFields =
      (contactModalContext === ContactModalEnum.ADD && newContactFields) ||
      (contactModalContext === ContactModalEnum.EDIT && newContactFields(selectedContact)) ||
      (contactModalContext === ContactModalEnum.DELETE &&
        newContactFields(selectedContact, true)) ||
      [];

    setContactModalFields(contactFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactModalContext]);

  return (
    <Modal show={Boolean(contactModalContext)} handleClose={() => clearContextAndSelectedContact()}>
      <div className={cn(styles['contact-deletion-confirm-modal'])}>
        <h3>{contactModalContext && t(modalsList[contactModalContext].title)}</h3>

        <p className={cn(styles['warning'])}>
          {contactModalContext && t(modalsList[contactModalContext].description)}
        </p>

        <div className={cn(styles['contact-modal-fields'])}>
          {metadata.map((item) => {
            return (
              <Field metadata={item} errors={errors} handleChange={handleChange} key={item.id} />
            );
          })}
        </div>

        <div className={cn(styles['actions'])}>
          <Button
            id="contact-modal-cancel"
            ariaLabel="contact-modal-cancel"
            buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
            tabIndex={0}
            clickHandler={() => clearContextAndSelectedContact()}
          >
            {t('common:cancel')}
          </Button>

          <Button
            id="contact-modal-action-button"
            ariaLabel="contact-modal-action-button"
            buttonStyleType={ButtonStyleTypeEnum.PRIMARY}
            className={cn(styles['delete'])}
            tabIndex={0}
            clickHandler={handleSubmit}
          >
            {contactModalContext && t(modalsList[contactModalContext].buttonText)}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ContactModal;
