import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation, Navigate, Outlet, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useQueryClient } from '@tanstack/react-query';
import Sidebar from './components/Sidebar';
import { requiresNoAuthRoutes, RoutePath } from '_shared/routes';
import styles from 'layouts/assets/main.module.scss';
import { useAppState } from 'store';
import Navbar from 'components/navbar';
import GptChatWidget from 'components/gptChatWidget';
import Footer from 'components/footer';
import { UserModalEnum } from './type';
import useBlur from '_shared/hooks/useBlur';
import AddUserModal from './components/AddUserModal/AddUserModal';
import InviteClientModal from './components/InviteClientModal';
import TaskModal from './components/AddTaskModal';
import { TaskModalEnum, UPDATE_TASK } from '../store/tasks/types';
import DeleteBookmarkModal from './components/DeleteBookmarkModal';
import Bookmarks from './components/Bookmarks';
import useToastMessage from '_shared/hooks/useToastMessage';
import AddNewSlideOut from './components/AddNewSlideOut';
import useBookmarks from '_shared/hooks/useBookmarks';

function MainLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state: globalState, dispatch: globalDispatch } = useAppState();
  const queryClient = useQueryClient();
  const { successToastMessage } = useToastMessage();
  const { accessToken } = globalState.system;
  const { getBookmarks, deleteBookmark } = useBookmarks();

  const addNewSlideOutRef = React.createRef<HTMLDivElement>();
  const [showAddNewSlideOut, setShowAddNewSlideOut] = useState(false);

  const bookmarksRef = React.createRef<HTMLDivElement>();
  const [showBookmarks, setShowBookmarks] = useState(false);
  const [bookmarkIdToDel, setBookmarkIdToDel] = useState<string | null>(null);

  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [userModalContext, setUserModalContext] = useState<UserModalEnum | null>(null);

  const bookmarks = getBookmarks();

  useEffect(() => {
    return () => {
      void queryClient.clear();
    };
  }, []);

  const blockRoute = !accessToken && !requiresNoAuthRoutes.includes(location.pathname);

  const onDeleteBookmark = () => {
    deleteBookmark(bookmarkIdToDel!);
    setBookmarkIdToDel(null);
    successToastMessage('Bookmark has been removed');
  };

  const onAddTask = () => {
    globalDispatch({
      type: UPDATE_TASK,
      payload: {
        ...globalState.tasks,
        modalContext: TaskModalEnum.ADD,
      },
    });
  };

  useBlur(bookmarksRef, () => setShowBookmarks(false));

  useBlur(addNewSlideOutRef, () => setShowAddNewSlideOut(false));

  return (
    <div className={classNames(styles['form'])}>
      <Navbar />

      <Sidebar
        showAddNewSlideOut={showAddNewSlideOut}
        showBookmarks={showBookmarks}
        toggleBookmarks={(show) => {
          setShowAddNewSlideOut(false);
          setShowBookmarks(show);
        }}
        toggleAddNewSlideOut={(show) => {
          setShowBookmarks(false);
          setShowAddNewSlideOut(show);
        }}
        onAddBookmark={() =>
          navigate(RoutePath.addBookmark, { state: { from: location.pathname } })
        }
      />

      {showAddNewSlideOut && (
        <AddNewSlideOut
          ref={addNewSlideOutRef}
          onAddTask={() => {
            setShowAddNewSlideOut(false);
            onAddTask();
          }}
          onAddClient={() => {
            setShowAddNewSlideOut(false);
            setShowAddClientModal(true);
          }}
        />
      )}

      {showBookmarks && (
        <Bookmarks
          ref={bookmarksRef}
          bookmarks={bookmarks}
          onClose={() => setShowBookmarks(false)}
          onDelete={(id) => setBookmarkIdToDel(id)}
        />
      )}

      <main className={styles.container}>
        <div className={classNames(styles['content-wrapper'])}>
          <div className={classNames(styles.content)}>
            {(blockRoute && (
              <Navigate to={RoutePath.login} replace state={{ from: location }} />
            )) || <Outlet />}
          </div>
        </div>
      </main>

      <GptChatWidget />

      <Footer />

      <InviteClientModal
        show={showAddClientModal}
        onClose={() => setShowAddClientModal(false)}
        onInviteBusiness={() => {
          setShowAddClientModal(false);
          setUserModalContext(UserModalEnum.INVITEBUSINESS);
        }}
        onAddBusiness={() => {
          setShowAddClientModal(false);
          setUserModalContext(UserModalEnum.ADDBUSINESS);
        }}
      />

      <TaskModal />

      {Boolean(userModalContext) && (
        <AddUserModal
          userModalContext={userModalContext}
          setUserModalContext={setUserModalContext}
        />
      )}

      {Boolean(bookmarkIdToDel) && (
        <DeleteBookmarkModal
          show={Boolean(bookmarkIdToDel)}
          onClose={() => setBookmarkIdToDel(null)}
          onConfirm={onDeleteBookmark}
          bookmark={bookmarks.find((x) => x.id === bookmarkIdToDel)!}
        />
      )}
    </div>
  );
}

export default MainLayout;
