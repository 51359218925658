/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-nested-ternary */
import i18next from 'i18next';

// Returns the value with currency sign.
export function convertToCurrency(labelValue: number | string) {
  if (!labelValue && labelValue !== 0) {
    return labelValue;
  }

  if (!Number(labelValue)) {
    return `${i18next.t('currency:symbol')}${labelValue}`;
  }

  return `${i18next.t('currency:symbol')}${numberWithCommas(labelValue)}`;
}

export function currencyIcon() {
  return i18next.t('currency:icon');
}

export function addCurrencySymbol(value: number | string) {
  return `${i18next.t('currency:symbol')}${value}`;
}

export function numberWithCommas(value: number | string) {
  if (value === ' - ') return value;
  const rounded = Math.round((+value + Number.EPSILON) * 100) / 100;

  return Number(rounded).toLocaleString('en-GB');
}

export function convertToShortenedCurrency(labelValue: number | string) {
  if (!labelValue && labelValue !== 0) {
    return labelValue;
  }

  if (!Number(labelValue)) {
    return `${i18next.t('currency:symbol')}${labelValue}`;
  }
  // Nine Zeroes for Billions
  const value =
    Math.abs(Number(labelValue)) >= 1.0e9
      ? `${Math.abs((Math.round(Number(labelValue) / 1.0e6) * 1.0e6) / 1.0e9)}B`
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? `${Math.abs((Math.round(Number(labelValue) / 1.0e3) * 1.0e3) / 1.0e6)}M`
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? `${Math.floor(Number(labelValue) / 1.0e3)}K`
      : Math.abs(Number(labelValue));

  return `${i18next.t('currency:symbol')}${value}`;
}

export const currencyToNumber = (currency: string | number = 0) => {
  return +currency.toString().replace(/,/g, '');
};

export const formatToCurrency = (
  newValue: string | number,
  returnZero: boolean = true,
  removeDecimals: boolean = true
) => {
  newValue = removeDecimals ? newValue : newValue;
  newValue = newValue
    .toString()
    .replace(/,/g, '')
    .replace(/[^0-9_.]+/g, '');
  const numberAndCurrency = `${newValue}`.split('.');
  numberAndCurrency[0] = _addThousandSeparator(numberAndCurrency[0], ',');
  const result =
    (numberAndCurrency.length > 1 && numberAndCurrency.join('.')) || numberAndCurrency[0];
  if (result === '0' && !returnZero) return '';
  return removeDecimals ? numberAndCurrency[0] : result;
};

// Transforms string into number.
export const _toNumber = (value: string) => {
  if (!value) return '';

  return Number(_onlyNumbers(value));
};

// Removes all dots and commas off of the number.
const _onlyNumbers = (input: string) => {
  return _toStr(input).replace(/\D+/g, '') || '0';
};

// Transforms the given value into a string.
const _toStr = (newValue: string | number) => {
  return newValue ? newValue.toString() : '';
};

// Transforms the given value to the correct currency format and adds the decimals places based on the desired precision.
const _numbersToCurrency = (numbers: string) => {
  const exp = Math.pow(10, 0);
  const float = parseFloat(numbers) / exp;
  return float.toFixed(0);
};

// Regex to split the number in groups up to three numbers by the separator character.
const _addThousandSeparator = (integer: string, separator: string) => {
  return integer.replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${separator}`);
};

// Concat the thousands and decimal with the separator character.
const _joinIntegerAndDecimal = (integer: string, decimal: string, separator: string) => {
  return decimal ? integer + separator + decimal : integer;
};

export const formatCurrency = (
  value: number | string,
  symbol: string = '',
  precision: number = 2
) => {
  if (precision !== undefined) {
    const valueWithPrecision = (
      typeof value === 'string' ? parseFloat(value) : value
    ).toLocaleString('en-GB', { minimumFractionDigits: precision });
    if (valueWithPrecision.startsWith('-')) {
      return `-${symbol}${valueWithPrecision.slice(1)}`;
    }
    return `${symbol}${valueWithPrecision}`;
  }
};
