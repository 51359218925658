import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CompanyDetailsContext } from 'pages/companyDetails/store/companyDetails.reducer';
import AccountDetails from './components/accountDetails';
import OverviewHook from './hooks/companyOverviewHook';
import FundingRequirements from 'pages/companyOverview/components/fundingRequirements';
import CompanyInfoCard from './components/companyInfoCard';
import FinancialSnapshotCard from './components/financialSnapshotCard';
import PeopleInfoCard from './components/peopleInfoCard';
import Accordion from 'components/accordion';
import Contacts from './components/contacts';

const Overview: React.FC = () => {
  const { state } = useContext(CompanyDetailsContext);
  const { id: companyId } = useParams();

  const { localState, handleToggleOnlyCurrentDirectors, setShowCompanyOwnerModal } = OverviewHook({
    companyId,
    state,
  });

  const { t } = useTranslation();

  return (
    <>
      <Accordion
        items={[
          {
            title: t('home:companydetails:fundingmatches:fundingrequirements:title'),
            content: <FundingRequirements companyId={companyId || ''} />,
            collapsedByDefault: false,
          },
          {
            title: t('home:companydetails:overview:info:title'),
            content: (
              <CompanyInfoCard
                {...{
                  companyId: companyId || '',
                  isLoading: state.isLoading,
                  error: state.company?.error,
                  fields: localState?.companyInfo?.fields,
                }}
              />
            ),
            collapsedByDefault: false,
          },
          {
            title: t('home:companydetails:overview:accountinfo:contact:title'),
            content: (
              <Contacts
                {...{
                  companyId: companyId || '',
                }}
              />
            ),
            collapsedByDefault: false,
          },
          {
            title: t('home:companydetails:overview:accountinfo:title'),
            content: (
              <AccountDetails
                {...{
                  companyId: companyId || '',
                }}
              />
            ),
            collapsedByDefault: false,
          },
          {
            title: t('home:companydetails:overview:financialsnapshot:title'),
            content: <FinancialSnapshotCard companyId={companyId || ''} />,
          },
          {
            title: t('home:companydetails:overview:people:title'),
            content: (
              <PeopleInfoCard
                {...{
                  peopleInfo: localState?.peopleInfo,
                  handleToggleOnlyCurrentDirectors,
                  setShowCompanyOwnerModal,
                }}
              />
            ),
          },
        ]}
      />
    </>
  );
};

export default Overview;
