import { TablePropType } from 'components/table/type';
import { FieldMetaDataPropType, FormFieldPropType } from '_shared/fieldValidation/types';

export enum ApplicationSectionEnum {
  NEW = 'new',
  LINKED = 'linked',
}

export enum ApplicationTabEnum {
  LOAN = 'Loan',
  EQUITY = 'Equity',
  GRANT = 'Grant',
}

export enum API_TO_SAVE {
  BP,
  CS_CONTACT,
  CS_ADDRESS,
}

export type ViewApplicationsType = {
  [key: string]: {
    [key: string]: TablePropType;
  };
};

export type NewApplicationType = {
  productId: string;
  isCovid19?: boolean;
  providerName?: string;
  productName?: string;
  productType?: ApplicationTabEnum;
  subCategory?: string;
  minSize?: number;
  maxSize?: number;
  minTerm?: number;
  maxTerm?: number;
  minInterestRate: number;
  productCategory: string;
  applicationStatus?: string;
};

export type LinkedApplicationType = {
  productId: string;
  applicationId: string;
  type?: ApplicationTabEnum;
  subCategory?: string;
  isCovid19?: boolean;
  providerName?: string;
  productName?: string;
  createdDate: string;
  dateModified: string;
  completion?: number;
};

export type APIApplicationFormType = {
  productId: string;
  productName: string;
  logoUri: string;
  loanType: string;
  subCategory: string;
  dateModified: string | Date;
  modifiedBy: string;
  completion: number;
  applicationSectionDetails: Array<ApplicationFormSectionsType>;
  applicationExtendedSectionDetails: Array<ApplicationFormExtendedSectionsType>;
};

export type ApplicationFormSectionsType = {
  sectionId: string;
  displayName: string;
  hasSubFields?: boolean;
  displayOrder: string;
  rootId?: string;
  totalNumberOfSectionItems: number;
  fieldValueDetails: Array<ApplicationFieldValueDetailsType>;
};

export type ApplicationFormExtendedSectionsType = ApplicationFormSectionsType & {
  rootId?: string;
  childrenDetails?: Array<ApplicationFormSectionsType>;
};

export type ApplicationFieldValueDetailsType = {
  name: string;
  value: string;
  dataType: string;
  displayName: string;
  options?: Array<any>;
  isReadonly: boolean;
  groupId?: any;
  groupName?: string;
  subGroupId?: any;
  subGroupName?: string;
};

type ApplicationChildrenType = FormFieldPropType & {
  groupId?: string;
  groupName?: string;
  subGroupId?: string;
};

export type ApplicationFormFieldsMetadataType = FieldMetaDataPropType & {
  count?: number;
  rootId?: string;
  totalCount?: number;
  hasSubFields?: boolean;
  subFields?: any;
  children?: Array<ApplicationChildrenType>;
};
