import React from 'react';

export type currentCompanyStateType = {
  isLoading: boolean;
  id?: string;
  company: any;
  overview?: any;
  info?: any;
  people?: any;
  financial?: any;
  owner?: string;
  user?: any;
  matches?: any;
  interest?: any;
  integration?: any;
  accountInfoData?: any;
};

export enum CompanyActionType {
  TOGGLE_LOADER,
  SET_ID,
  SET_COMPANY,
  SET_OVERVIEW,
  SET_INFO,
  SET_PEOPLE,
  SET_FINANCIAL,
  SET_USER,
  SET_MATCHES,
  SET_INTEREST_LIST,
  SET_INTEGRATION,
  SET_ACCOUNT_INFO_DATA,
}
export type Action = {
  type: CompanyActionType;
  payload?: any;
};

export function currentCompanyReducer(state: currentCompanyStateType, action: Action) {
  switch (action.type) {
    case CompanyActionType.TOGGLE_LOADER:
      return {
        ...state,
        isLoading: typeof action.payload === undefined ? !state.isLoading : action.payload,
      };
    case CompanyActionType.SET_COMPANY:
      return {
        ...state,
        company: { ...state.company, ...action.payload },
      };
    case CompanyActionType.SET_ID:
      return {
        ...state,
        id: action.payload,
      };
    case CompanyActionType.SET_OVERVIEW:
      return {
        ...state,
        info: action.payload,
      };
    case CompanyActionType.SET_INFO:
      return {
        ...state,
        info: action.payload,
      };
    case CompanyActionType.SET_PEOPLE:
      return {
        ...state,
        people: action.payload,
      };
    case CompanyActionType.SET_FINANCIAL:
      return {
        ...state,
        financial: action.payload,
      };
    case CompanyActionType.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case CompanyActionType.SET_MATCHES:
      return {
        ...state,
        matches: action.payload,
      };
    case CompanyActionType.SET_INTEREST_LIST:
      return {
        ...state,
        interest: action.payload,
      };
    case CompanyActionType.SET_INTEGRATION:
      return {
        ...state,
        integration: action.payload,
      };
    case CompanyActionType.SET_ACCOUNT_INFO_DATA:
      return {
        ...state,
        accountInfoData: action.payload,
      };
    default:
      return state;
  }
}

export const currentCompanyInitialState = {
  isLoading: true,
  company: {},
  accountInfoData: {},
};
export const CompanyDetailsContext = React.createContext<{
  state: currentCompanyStateType;
  dispatch: React.Dispatch<Action>;
  refreshCompanyData: () => Promise<void>;
}>({ state: currentCompanyInitialState, dispatch: () => {}, refreshCompanyData: async () => {} });
