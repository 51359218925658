import { IFundingDetails, IInsight } from 'components/insightCard/type';
import { ActiveTabs } from 'pages/companyOverview/components/fundingRequirements/types';
import { FieldMetaDataPropType } from '_shared/fieldValidation/types';

export enum OfferTypes {
  LOANS = 'Loans',
  EQUITY = 'Equity',
  GRANTS = 'Grants',
}

export enum NonSwoopEquityDisplayOptions {
  MESSAGE = 'message',
  HEADER = 'header',
}

export type OfferDataType = {
  data: any[];
  fields: any[];
};

export type FundingMatchesContentProps = {
  companyId: string;
  isMobileMenuOpen: boolean;
  toggleMenu: () => void;
  matches: { [key: string]: { [key: string]: any }[] };
  insights: { data: IInsight[] };
  selectedOfferType: OfferTypes;
  loading: boolean;
  error: boolean;
  currentTab: ActiveTabs;
  isComponentView?: boolean;
  dealId?: string;
  updateFunders?: Function;
  editRequirements: Function;
  requestedAmountByTab: RequestedAmountByFormId;
  fundingDetails: IFundingDetails;
};

export type FundingMatchesMenuProps = {
  isMobileMenuOpen: boolean;
  selectedOfferType: string;
  offerData: FundingMatchesMenuOfferData;
  companyId: string;
  toggleMenu: () => void;
  toggleSelectedOfferType: (event: React.ChangeEvent<HTMLInputElement>) => void;
  refresh: () => void;
  handleChange: (tab: any) => void;
  activeTabs: string[];
  currentTab: string;
  subtabs: { title: string; keys: string[] }[];
};

export type FundingMatchesMenuHookProps = {
  selectedOfferType: string;
  companyId: string;
  offerData: FundingMatchesMenuOfferData;
  refresh: () => void;
};

type FundingMatchesMenuOfferData = {
  data: { [key: string]: any };
  fields: FieldMetaDataPropType[];
};

export type NonSwoopEquityPropTypes = {
  customContainerClass?: string;
};

export type RequestedAmountByFormId = Record<string, number | null>;
