import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './fundingMatchCard.module.scss';
import Button from 'components/button';
import { RoutePath } from '_shared/routes';
import LogoNotFound from 'assets/images/logo-not-found.svg';
import { convertToShortenedCurrency } from '_shared/utils/currency';
import { OfferTypes } from '../../../types';

type FundingMatchCardProps = {
  opportunity: any;
  offerType: OfferTypes;
  isInDeal: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  readonly: boolean;
  addDealLabel?: string;
  onAddToDeal: (opportunityId: string, opportunity: any) => void;
};

const FundingMatchCard: React.FC<FundingMatchCardProps> = ({
  opportunity,
  offerType,
  readonly,
  isLoading,
  disabled,
  isInDeal,
  addDealLabel = 'Add to deal',
  onAddToDeal,
}) => {
  const fundingRange = `${convertToShortenedCurrency(opportunity.minSize || 0)} - ${
    (opportunity.maxSize && convertToShortenedCurrency(opportunity.maxSize)) || 'N/A'
  }`;

  return (
    <div className={styles['matches-card']} data-testid="matches-tile">
      <span
        className={styles['trading-name-tooltip']}
        data-label={opportunity.tradingName ?? opportunity.providerName}
      >
        <div className={styles['logo-wrapper']}>
          <img
            src={opportunity.logoUri || LogoNotFound}
            alt={opportunity?.providerName || 'company logo'}
            className={styles.logo}
          />
        </div>
      </span>
      <div className={styles['provider-details']}>
        <div>{opportunity.productName}</div>
        <div>{opportunity.providerName}</div>
      </div>
      <div className={styles['funding']}>
        <div>Offers between</div>
        <div>{fundingRange}</div>
      </div>

      {offerType !== OfferTypes.LOANS ? (
        <div className={styles['match-details']}>
          <div className={styles['match-details-items']}>
            <div className={styles['match-details-item']}>
              <div>{opportunity.speedName || '-'}</div>
              <div>Decision Time</div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles['match-details']}>
          <span className={styles['icon-wrapper']}>
            <i className={cn('material-icons', styles['icon'])}>contacts</i>Client
          </span>
          <div className={styles['match-details-items']}>
            <div className={styles['match-details-item']}>
              <div>{opportunity.speedName || '-'}</div>
              <div>Decision Time</div>
            </div>
            <div className={styles['match-details-item']}>
              <div>{(opportunity.aprMin && `${opportunity.aprMin} %`) || '-'}</div>
              <div>Rates from</div>
            </div>
          </div>
        </div>
      )}
      <div className={styles['actions']}>
        {!readonly &&
          (isInDeal ? (
            <Button className={styles['existing-application-button']} disabled>
              In Deal
            </Button>
          ) : (
            <Button
              className={styles['start-application-button']}
              clickHandler={() => onAddToDeal(opportunity.opportunityId, opportunity)}
              loading={isLoading}
              disabled={disabled}
            >
              {addDealLabel}
            </Button>
          ))}
        <Link
          to={RoutePath.productinformation.replace(':id', opportunity.opportunityId)}
          target="_blank"
          rel="noopener noreferrer"
        >
          See Info
        </Link>
      </div>
    </div>
  );
};

export default FundingMatchCard;
