import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './footer.module.scss';
import { useAppState } from 'store';

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const { state: globalState } = useAppState();

  const style = globalState.system.currentUser ? 'main' : 'login';

  return (
    <div className={cn(styles['nav-links'], styles[style])} data-testid={style}>
      <a target="_blank" rel="noopener noreferrer" href={t('main:bottom:website:link')}>
        {t('main:bottom:website:label')}
      </a>
      <a target="_blank" rel="noopener noreferrer" href={t('main:bottom:privacyPolicy:link')}>
        {t('main:bottom:privacyPolicy:label')}
      </a>
      <a target="_blank" rel="noopener noreferrer" href={t('main:bottom:tc:link')}>
        {t('main:bottom:tc:label')}
      </a>
      <span>©Swoop {currentYear}</span>
    </div>
  );
};

export default Footer;
