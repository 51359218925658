import { LinkedApplicationType } from 'pages/companyApplicationForm/types';
import { createContext } from 'react';
import { OfferDataType, OfferTypes } from '../types';

export type FundingMatchesType = {
  companyId?: null | string;
  isMobileMenuOpen: boolean;
  linkedApplications: LinkedApplicationType[] | null;
  currentClient: {
    hasCurrentClient: boolean;
    currentClientId: string;
    currentClientName: string;
    currentClientInitials: string;
  };
  offerData: {
    loading: boolean;
    error: boolean;
    [OfferTypes.LOANS]: OfferDataType;
    [OfferTypes.GRANTS]: OfferDataType;
    [OfferTypes.EQUITY]: OfferDataType;
  };
  matches: {
    data: any[];
    fields: any;
    error: boolean;
    loading: boolean;
  };
  insights: {
    data: any[];
  };
  productsForNonMatches: any[];
  refresh: number;
  sortOffersBy: {
    [OfferTypes.LOANS]: string;
    [OfferTypes.GRANTS]: string;
    [OfferTypes.EQUITY]: string;
  };
};

export enum ActionType {
  SET_MOBILE_MENU_IS_OPEN,
  SET_OFFER_TYPE_DATA,
  SET_LINKED_APPLICATIONS,
  SET_OFFER_LOADING,
  SET_OFFER_ERROR,
  SET_FUNDING_MATCHES_BY_OFFER_TYPE,
  SET_FUNDING_MATCHES_DATA,
  SET_FUNDING_MATCHES_LOADING,
  SET_FUNDING_MATCHES_ERROR,
  SET_REFRESH,
  SET_COMPANY_ID,
  SET_SORT_OFFERS_BY,
  SET_CURRENT_CLIENT,
  SET_PRODUCTS_FOR_NON_MATCHES,
  SET_FUNDING_MATCHES_INSIGHTS_DATA,
  SET_FUNDING_MATCHES_INSIGHTS_LOADING,
  SET_FUNDING_MATCHES_INSIGHTS_ERROR,
  SET_SELECTED_OFFER_TYPE,
}

export const initialState = {
  companyId: null,
  isMobileMenuOpen: false,
  selectedOfferType: OfferTypes.LOANS,
  linkedApplications: null,
  currentClient: {
    hasCurrentClient: false,
    currentClientId: '',
    currentClientName: '',
    currentClientInitials: '',
  },
  offerData: {
    loading: false,
    error: false,
    [OfferTypes.LOANS]: {
      data: [],
      fields: [],
    },
    [OfferTypes.EQUITY]: {
      data: [],
      fields: [],
    },
    [OfferTypes.GRANTS]: {
      data: [],
      fields: [],
    },
  },
  matches: {
    data: [],
    fields: {},
    loading: false,
    error: false,
  },
  insights: {
    data: [],
  },
  productsForNonMatches: [],
  refresh: 0,
  sortOffersBy: {
    [OfferTypes.LOANS]: 'aprMin',
    [OfferTypes.EQUITY]: 'aprMax',
    [OfferTypes.GRANTS]: 'speedName',
  },
} as FundingMatchesType;

export type Action = {
  type: ActionType;
  payload: any;
};

export function fundingMatchesReducer(state: FundingMatchesType, action: Action) {
  switch (action.type) {
    case ActionType.SET_COMPANY_ID:
      return {
        ...state,
        companyId: action.payload.companyId,
      };

    case ActionType.SET_MOBILE_MENU_IS_OPEN:
      return {
        ...state,
        isMobileMenuOpen: action.payload.isMobileMenuOpen,
      };

    case ActionType.SET_LINKED_APPLICATIONS:
      return {
        ...state,
        linkedApplications: action.payload,
      };

    case ActionType.SET_OFFER_LOADING:
      return {
        ...state,
        offerData: { ...state.offerData, loading: action.payload.loading },
      };

    case ActionType.SET_OFFER_ERROR:
      return {
        ...state,
        offerData: { ...state.offerData, error: action.payload.error },
      };

    case ActionType.SET_OFFER_TYPE_DATA:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          ...action.payload,
        },
      };
    case ActionType.SET_FUNDING_MATCHES_DATA:
      return {
        ...state,
        matches: { ...state.matches, ...action.payload },
      };

    case ActionType.SET_FUNDING_MATCHES_INSIGHTS_DATA:
      return {
        ...state,
        insights: { ...state.insights, ...action.payload },
      };

    case ActionType.SET_FUNDING_MATCHES_BY_OFFER_TYPE:
      return {
        ...state,
        matches: { ...state.matches, fields: action.payload },
      };

    case ActionType.SET_PRODUCTS_FOR_NON_MATCHES:
      return {
        ...state,
        productsForNonMatches: action.payload,
      };

    case ActionType.SET_FUNDING_MATCHES_LOADING:
      return {
        ...state,
        matches: { ...state.matches, loading: action.payload.loading },
      };

    case ActionType.SET_FUNDING_MATCHES_ERROR:
      return {
        ...state,
        matches: { ...state.matches, error: action.payload.error },
      };

    case ActionType.SET_REFRESH:
      return {
        ...state,
        refresh: state.refresh + 1,
      };

    case ActionType.SET_SORT_OFFERS_BY:
      if (!action.payload?.sortBy || !action.payload.offerType) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        sortOffersBy: { ...state.sortOffersBy, [action.payload.offerType]: action.payload.sortBy },
      };

    case ActionType.SET_CURRENT_CLIENT: {
      return {
        ...state,
        currentClient: {
          ...state.currentClient,
          ...action.payload,
        },
      };
    }

    case ActionType.SET_SELECTED_OFFER_TYPE: {
      return {
        ...state,
        selectedOfferType: action.payload,
      };
    }

    default:
      return state;
  }
}

export const FundingMatchesContext = createContext<{
  state: FundingMatchesType;
  dispatch: React.Dispatch<Action>;
}>({ state: initialState, dispatch: () => {} });
