import styles from 'pages/companyOverview/assets/accountDetails.module.scss';
import { APIContactType } from '../types';
import { ValidationTypes, FieldMetaDataPropType, FieldTypes } from '_shared/fieldValidation/types';
import { TablePropType } from 'components/table/type';
import i18next from 'i18next';
import { ContactModalEnum } from '../components/contactModal/type';

const originalContactId = 'S00000000-0000-0000-0000-000000000000';

const defaultHeaderStyles = {
  header: styles['th-header'],
};

const defaultCellStyles = {
  cell: styles['td-cell'],
};

export const contactFields = (
  data: Array<APIContactType>,
  setContactModalContext: Function,
  setSelectedContact: Function
): TablePropType => {
  return {
    header: [
      {
        id: 'contact-first-name',
        value: i18next.t('home:companydetails:overview:accountinfo:contact:name'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        id: 'contact-last-name',
        value: i18next.t('home:companydetails:overview:accountinfo:contact:lastname'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        id: 'contact-email',
        value: i18next.t('home:companydetails:overview:accountinfo:contact:email'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        id: 'contact-phonenumber',
        value: i18next.t('home:companydetails:overview:accountinfo:contact:phonenumber'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        id: 'contact-role',
        value: i18next.t('home:companydetails:overview:accountinfo:contact:role'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        id: 'edit-contact',
        value: '',
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        id: 'delete-contact',
        value: '',
        classname: {
          ...defaultHeaderStyles,
        },
      },
    ],
    row: {},
    classname: { table: styles.table },
    emptyMessage: i18next.t('home:companydetails:overview:people:emptystate'),
    items: data.map((contact: APIContactType) => {
      return [
        {
          id: `${contact?.id}-name`,
          classname: {
            ...defaultCellStyles,
          },
          default: contact?.firstName || '-',
        },
        {
          id: `${contact?.id}-last-name`,
          classname: {
            ...defaultCellStyles,
          },
          default: contact?.lastName || '-',
        },
        {
          id: `${contact?.id}-email`,
          classname: {
            ...defaultCellStyles,
          },
          default: contact?.email || '-',
        },
        {
          id: `${contact?.id}-phonenumber`,
          classname: {
            ...defaultCellStyles,
          },
          default: contact?.contactNumber || '-',
        },
        {
          id: `${contact?.id}-role`,
          classname: {
            ...defaultCellStyles,
          },
          default: contact?.roleInCompany?.join?.(', ') || '-',
        },
        {
          id: `${contact?.id}-edit`,
          icon: contact.id === originalContactId ? 'info' : 'edit',
          tooltip:
            contact.id === originalContactId &&
            i18next
              .t('home:companydetails:overview:accountinfo:contact:originalcontact')
              .toString(),
          classname: {
            ...defaultCellStyles,
            icon: contact.id === originalContactId ? styles['disabled-icon'] : '',
          },
          actionCb: () => {
            if (contact.id === originalContactId) return;
            setContactModalContext(ContactModalEnum.EDIT);
            setSelectedContact(contact);
          },
          default: '',
        },
        {
          id: `${contact?.id}-delete`,
          icon: contact.id === originalContactId ? '' : 'delete',
          classname: {
            ...defaultCellStyles,
          },
          actionCb: () => {
            if (contact.id === originalContactId) return;
            setContactModalContext(ContactModalEnum.DELETE);
            setSelectedContact(contact);
          },
          default: '',
        },
      ];
    }),
  };
};

export const newContactFields = (
  data: any,
  isDeleting: boolean = false
): Array<FieldMetaDataPropType> => {
  return [
    {
      id: 'new-field-first-name',
      name: 'firstName',
      label: 'home:companydetails:overview:accountinfo:contact:name',
      validationtype: ValidationTypes.TEXT,
      value: data.firstName ? data.firstName : '',
      placeholder: 'home:companydetails:overview:accountinfo:contact:name',
      type: 'text',
      fieldType: FieldTypes.TEXT,
      required: !isDeleting,
      disabled: isDeleting,
    },
    {
      id: 'new-field-last-name',
      name: 'lastName',
      label: 'home:companydetails:overview:accountinfo:contact:lastname',
      validationtype: ValidationTypes.TEXT,
      value: data.lastName ? data.lastName : '',
      placeholder: 'home:companydetails:overview:accountinfo:contact:lastname',
      type: 'text',
      fieldType: FieldTypes.TEXT,
      required: !isDeleting,
      disabled: isDeleting,
    },
    {
      id: 'new-field-email',
      name: 'email',
      label: 'home:companydetails:overview:accountinfo:contact:email',
      validationtype: ValidationTypes.EMAIL,
      value: data.email ? data.email : '',
      placeholder: 'home:companydetails:overview:accountinfo:contact:email',
      type: 'email',
      required: !isDeleting,
      fieldType: FieldTypes.TEXT,
      disabled: isDeleting,
    },
    {
      id: 'new-field-phone-number',
      name: 'contactNumber',
      label: 'home:companydetails:overview:accountinfo:contact:phonenumber',
      validationtype: ValidationTypes.NUMERIC,
      value: data.contactNumber ? data.contactNumber : '',
      placeholder: 'home:companydetails:overview:accountinfo:contact:phonenumber',
      type: 'tel',
      required: !isDeleting,
      fieldType: FieldTypes.TEXT,
      disabled: isDeleting,
    },
    {
      id: 'new-field-role',
      name: 'roleInCompany',
      label: 'home:companydetails:overview:accountinfo:contact:role',
      validationtype: ValidationTypes.MULTISELECT,
      required: !isDeleting,
      value: data?.roleInCompany ?? [],
      placeholder: data.roleInCompany
        ? ''
        : 'home:companydetails:overview:accountinfo:contact:role',
      options: [
        {
          label: 'Director',
          value: 'Director',
        },
        {
          label: 'Guarantor',
          value: 'Guarantor',
        },
        {
          label: 'Shareholder',
          value: 'Shareholder',
        },
      ],
      fieldType: FieldTypes.MULTISELECT,
      disabled: isDeleting,
    },
  ];
};
