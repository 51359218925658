import React from 'react';
import cn from 'classnames';
import styles from 'pages/fundingMatches/assets/nonSwoopEquityMessage.module.scss';
import { useTranslation } from 'react-i18next';
import { NonSwoopEquityPropTypes } from 'pages/fundingMatches/types';

const NonSwoopEquityMessage: React.FC<NonSwoopEquityPropTypes> = ({
  customContainerClass = '',
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(styles['non-swoop-equity-container'], {
        [styles[customContainerClass]]: customContainerClass,
      })}
    >
      <div className={cn(styles['non-swoop-equity-message-container'])}>
        <h3 className={cn(styles['instruction-header'])}>
          {t('home:companydetails:fundingmatches:nonswoopequitymessage:instructionheader1')}
        </h3>
        <p className={cn(styles['instruction-subtitle'])}>
          {t('home:companydetails:fundingmatches:nonswoopequitymessage:instructionsubtitle')}
        </p>
        <ol className={cn(styles['instruction-list'])}>
          <li>
            {t('home:companydetails:fundingmatches:nonswoopequitymessage:instructionlistitem1')}
          </li>
          <li>
            {t('home:companydetails:fundingmatches:nonswoopequitymessage:instructionlistitem2')}
          </li>
        </ol>
        <h3 className={cn(styles['instruction-header'])}>
          {t('home:companydetails:fundingmatches:nonswoopequitymessage:instructionheader2')}
        </h3>
        <ol className={cn(styles['instruction-list'])}>
          <li>
            {t('home:companydetails:fundingmatches:nonswoopequitymessage:instructionlistitem3')}
          </li>
          <li>
            {t('home:companydetails:fundingmatches:nonswoopequitymessage:instructionlistitem4')}
          </li>
          <li>
            {t('home:companydetails:fundingmatches:nonswoopequitymessage:instructionlistitem5')}
          </li>
        </ol>
      </div>
    </div>
  );
};

export default NonSwoopEquityMessage;
