import { TCountry } from 'store/system/types';
import { env } from '_shared/utils';
const country: TCountry = env('REACT_APP_COUNTRY');

const features = {
  loansEnabled: {
    unitedkingdom: true,
    australia: true,
    canada: true,
  }[country],
  grantsEnabled: {
    unitedkingdom: true,
    australia: false,
    canada: true,
  }[country],
  equityEnabled: {
    unitedkingdom: true,
    australia: false,
    canada: true,
  }[country],
  externalGroupEquityEnabled: {
    unitedkingdom: false,
    australia: true,
    canada: false,
  }[country],
  assignUsersEnabled: true,
  matchesPageEnabled: false,
  applicationsPageEnabled: true,
  specificAccountInfoEnabled: false,
  searchTasksFromApiEnabled: false,
  formScoreHistoryEnabled: true,
  creditReportsEnabled: {
    unitedkingdom: true,
    australia: false,
    canada: false,
  }[country],
  bankIntegrationEnabled: {
    unitedkingdom: true,
    australia: false,
    canada: false,
  }[country],
  companyNumberLinkEnabled: {
    unitedkingdom: true,
    australia: false,
    canada: false,
  }[country],
  seisEisEnabled: {
    unitedkingdom: true,
    australia: false,
    canada: false,
  }[country],
  sidebarEducationLink: {
    unitedkingdom: true,
    australia: false,
    canada: false,
  }[country],
  sidebarProductsEnabled: {
    unitedkingdom: true,
    australia: true,
    canada: false,
  }[country],
  savingsProductsEnabled: {
    unitedkingdom: true,
    australia: false,
    canada: false,
  }[country],
};

export default features;
